import { Button, Input, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { FiAlertCircle, FiCopy, FiExternalLink } from "react-icons/fi";
import {
  useBalance,
  useChains,
  useDisconnect,
  useEstimateFeesPerGas,
  useEstimateGas,
  useGasPrice,
  useSwitchChain,
} from "wagmi";
import {
  useWalletInfo,
  useWeb3Modal,
  useWeb3ModalState,
} from "@web3modal/wagmi/react";
import { useConnect, useAccount, useWriteContract } from "wagmi";
import { injected } from "wagmi/connectors";
import {
  AmountConverter,
  GetMaticExchangeRate,
  StablecoinWarperFunction,
  StablecoinWarperFunctionWithOutFormatting,
  fetchStableCoinPrice,
  newNumberformat,
  toFixedWithoutRounding,
} from "../../Helper/HelperFunction";
import { parseEther, parseUnits, BaseError, ProviderRpcError } from "viem";
import {
  GetProfile,
  GetWalletBalance,
  HandelError,
  clientApi,
  gitBookUrl,
} from "../../Utils/AxiosCall";
import "./style.css";
import { Modal } from "antd";
import { ethers } from "ethers";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import netWorkIcon from "../../Assets/images/polygon-amoy-network.svg";
import dayjs from "dayjs";
import errorIcon from "../../Assets/images/error-icon.svg";
import successIcon from "../../Assets/images/wallet_success.svg";
import {
  setDepositTransactionStatus,
  setWithdrawalTransactionStatus,
} from "../../Store/reducers/global";
import { IoMdArrowDropdown } from "react-icons/io";
import { MetaMaskAvatar } from "react-metamask-avatar";
import add_circle from "../../Assets/images/add-circle-outline.svg";
import close_icon from "../../Assets/images/IconButton.svg";
import SignUpInputBox from "../Components/Atom/SignUpInputBox/SignUpInputBox";
import Modal2faWithdraw from "../Modal2fa/Modal2faWithdraw";
import checkIcons from "../../Assets/images/check-icon-invest.svg";
import { walletLogos } from "../../Helper/CustomIcons";
const { Option } = Select;
const OpenWallet = () => {
  const { open, close } = useWeb3Modal();

  try {
    setTimeout(() => {
      open();
    }, 2000); // 2 seconds delay
  } catch (error) {
    console.error("Failed to open wallet:", error);
  }
};

export default function WebThreeWithdraw({
  addresss,
  setStableCoin,
  setStableCoinLoading,
  setTransactionAmount,
}) {
  const SelectedStableCoin = useSelector(
    (state) => state.global.SelectedStableCoin
  );
  const account = useAccount();
  const chains = useChains();
  const { selectedNetworkId } = useWeb3ModalState();
  const { chains: availableChains, switchChain } = useSwitchChain();
  const [assetSelected, setAssetSelected] = useState("");
  const [network, setNetwork] = useState(chains[0]?.name);
  const [tokenAmount, setTokenAmount] = useState("");
  const [tokenLabel, setTokenLabel] = useState("USDC");
  const [destinationAddress, setDestinationAddress] = useState("");
  const [exchangeRate, setExchangeRate] = useState({});
  const { open, close } = useWeb3Modal();
  const [reviewState, setReviewState] = useState(false);
  const [originalAssetList, setOriginalAssetList] = useState([]);
  const [asset_list, setAsset_list] = useState([]);
  const [transitionHash, setTransitionHash] = useState("");
  const [convertedRate, setConvertedRate] = useState(0);
  const [netWorkFee, setNetWorkFee] = useState(0);
  const [FUBalances, setFUBalances] = useState("");
  const [SelectedWalletDetails, setSelectedWalletDetails] = useState({});
  const handleCopy = () => {
    if (addresss) {
      navigator.clipboard.writeText(addresss);
      message.success("Address copied to clipboard");
    } else {
      message.error("No address to copy");
    }
  };

  const convertToAssetList = async (originalArray) => {
    return originalArray.map((item) => {
      return {
        label: item?.tokenName,
        value: item?.contractAddress,
      };
    });
  };
  const getNetworkAddress = async () => {
    try {
      const response = await clientApi.get(
        `/api/investor/wallet/network-address?chainId=${chains[0]?.id}`
      );
      if (response?.status === 200) {
        console.log(response?.data);
        setOriginalAssetList(response?.data);
        setAsset_list(await convertToAssetList(response?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(network);

  const {
    data: tokenBalance,
    isLoading: tokenLoading,
    error: tokenError,
  } = useBalance({
    address: account?.address,
    token: assetSelected, // Replace with the token contract address // Link Token
    chainId: chains[0]?.id, // Replace with the chain ID (e.g., 1 for Ethereum mainnet)
  });
  // useEffect(() => {

  //     if (tokenBalance) {
  //         setStableCoin(tokenBalance?.formatted)
  //     }
  //     if (tokenLoading) {
  //         setStableCoinLoading(true)
  //     } else {
  //         setStableCoinLoading(false)
  //     }
  // }, [tokenBalance, tokenLoading, tokenError])

  const checkNetwork = () => {
    if (!tokenAmount) {
      return message.error("Please enter a valid amount to withdraw");
    }
    if (parseFloat(tokenAmount) < 10) {
      return message.error(`Minimum withdraw ${SelectedStableCoin} is 10`);
    }
    if (parseFloat(tokenAmount) > 1000000) {
      return message.error(`Maximum withdraw ${SelectedStableCoin} is 1000000`);
    }
    //   console.log(tokenAmount,FUBalances)
    //   return
    if (parseFloat(FUBalances) <= 0) {
      // console.log("i am in")
      return message.error("Insufficient balance to withdraw");
    }
    //     console.log(parseFloat(FUBalances)<=0)
    //   return
    if (selectedNetworkId && chains) {
      if (selectedNetworkId != chains[0]?.id) {
        switchChain({ chainId: chains[0]?.id })?.catch(() => {
          open({ view: "Networks" });
        });
      } else {
        setReviewState(true);
      }
    } else {
      message.error("Please Check network");
      setReviewState(false);
    }
  };

  useEffect(() => {
    if (account?.isConnected && chains && chains.length > 0) {
      // close()
    }
  }, [account]);

  useEffect(() => {
    setTransactionAmount(tokenAmount);
  }, [tokenAmount]);
  useEffect(() => {
    getNetworkAddress();
  }, [chains]);

  useEffect(() => {
    console.log(FUBalances);
  }, [FUBalances]);

  useEffect(() => {
    if (reviewState) {
      setReviewState(false);
    }
  }, [SelectedStableCoin]);
  // const[walletListDetails,setWalletListDetails]=useState({})
  // const [SelectedWalletAddress, setSelectedWalletAddress] = useState("")
  // const [SelectedWalletDetails, setSelectedWalletDetails] = useState({})
  // useEffect(() => {
  //     const Get= async()=>{
  //       try {
  //         const response= await GetWalletBalance()
  //         setWalletListDetails(response)
  //       } catch (error) {

  //       }
  //     }
  //     Get()
  //   }, [])
  //  useEffect(() => {
  //     console.log(account?.address)
  //     if (walletListDetails?.walletBalances?.length > 0&& account?.address) {
  //         const selectedWallet = walletListDetails?.walletBalances.find(wallet => wallet?.walletAddress === account?.address);
  //         console.log(selectedWallet)
  //         setSelectedWalletDetails(selectedWallet);
  //     }
  //  }, [walletListDetails,account?.address])

  return (
    <>
      <div className="" style={{}}>
        {!reviewState ? (
          <ReviewAndConfirm
            SelectedWalletDetails={SelectedWalletDetails}
            setSelectedWalletDetails={setSelectedWalletDetails}
            network={network}
            handleCopy={handleCopy}
            assetSelected={assetSelected}
            setAssetSelected={setAssetSelected}
            checkNetwork={checkNetwork}
            asset_list={asset_list}
            addresss={addresss}
            reviewState={reviewState}
            setReviewState={setReviewState}
            tokenBalance={tokenBalance}
            tokenAmount={tokenAmount}
            setTokenAmount={setTokenAmount}
            tokenLabel={tokenLabel}
            setTokenLabel={setTokenLabel}
            originalAssetList={originalAssetList}
            destinationAddress={destinationAddress}
            setDestinationAddress={setDestinationAddress}
            convertedRate={convertedRate}
            netWorkFee={netWorkFee}
            tokenLoading={tokenLoading}
            FUBalances={FUBalances}
            setFUBalances={setFUBalances}
          />
        ) : (
          <>
            <ConfirmWithdraw
              SelectedWalletDetails={SelectedWalletDetails}
              setSelectedWalletDetails={setSelectedWalletDetails}
              network={network}
              handleCopy={handleCopy}
              assetSelected={assetSelected}
              setAssetSelected={setAssetSelected}
              asset_list={asset_list}
              addresss={addresss}
              reviewState={reviewState}
              setReviewState={setReviewState}
              tokenBalance={tokenBalance}
              tokenAmount={tokenAmount}
              setTokenAmount={setTokenAmount}
              chains={chains}
              tokenLabel={tokenLabel}
              setTokenLabel={setTokenLabel}
              destinationAddress={destinationAddress}
              setDestinationAddress={setDestinationAddress}
              transitionHash={transitionHash}
              setTransitionHash={setTransitionHash}
              convertedRate={convertedRate}
              netWorkFee={netWorkFee}
            />
          </>
        )}
      </div>
    </>
  );
}
const ReviewAndConfirm = ({
  SelectedWalletDetails,
  setSelectedWalletDetails,
  network,
  handleCopy,
  assetSelected,
  setAssetSelected,
  asset_list,
  checkNetwork,
  setTokenAmount,
  tokenAmount,
  tokenBalance,
  setTokenLabel,
  originalAssetList,
  setDestinationAddress,
  tokenLoading,
  FUBalances,
  setFUBalances,
}) => {
  const SelectedStableCoin = useSelector(
    (state) => state.global.SelectedStableCoin
  );
  //////////////////////////////////////////////////////////////////////////////////////
  const { address, status } = useAccount();
  const { disconnect } = useDisconnect();
  const { connectAsync, connect, connectors } = useConnect();
  const { walletInfo } = useWalletInfo();
  const [walletsName, setWalletsName] = useState("");
  const [walletConnectDetails, setWalletConnectDetails] = useState({});
  const [SelectedWalletAddress, setSelectedWalletAddress] = useState("");
  const [walletListDetails, setWalletListDetails] = useState({});
  useEffect(() => {
    const connector = findConnectorById(connectors, walletsName);
    if (connector) {
      console.log("Connector found:", connector);
      setWalletConnectDetails(connector);
    } else {
      console.log("Connector not found");
    }
  }, [connectors, walletInfo?.name, walletsName, SelectedWalletAddress]);

  const findConnectorById = (connectors, id) => {
    return connectors.find((connector) => connector.name === id) || null;
  };

  const handleConnect = async (walletsName) => {
    const connector = findConnectorById(connectors, walletsName);
    if (connector) {
      console.log("Connector found:", connector);
      setWalletConnectDetails(connector);
    } else {
      console.log("Connector not found");
    }
    if (connector) {
      await connect({ connector: connector });
      console.log("Connected to wallet:", connector?.name);
    } else {
      console.log("Connector not found");
      message.error("Wallet not found");
    }
  };

  useEffect(() => {
    try {
      if (
        address &&
        !walletListDetails?.walletBalances?.some(
          (wallet) => wallet.walletAddress === address
        )
      ) {
        const updatedWalletBalances = [
          ...walletListDetails.walletBalances,
          {
            walletName: walletInfo?.name ?? "", // or any default name you want to give
            walletAddress: address,
            walletBalance: 0, // or any default balance you want to give
          },
        ];
        setWalletListDetails({
          ...walletListDetails,
          walletBalances: updatedWalletBalances,
        });
      }
    } catch (error) {
      console.error("Error updating wallet list details:", error);
    }
  }, [walletListDetails, address]);
  const Get = async () => {
    try {
      const response = await GetWalletBalance();
      if (
        address &&
        !response?.walletBalances?.some(
          (wallet) => wallet.walletAddress === address
        )
      ) {
        response.walletBalances.push({
          walletName: walletInfo?.name || null, // or any default name you want to give
          walletAddress: address,
          walletBalance: 0, // or any default balance you want to give
        });
      }
      setWalletListDetails(response);
    } catch (error) {}
  };
  useEffect(() => {
    Get();
  }, [SelectedStableCoin]);

  const shortenAddress = (address) => {
    return `${address?.substring(0, 8)}...${address?.substring(
      address?.length - 8
    )}`;
  };
  const handleChange = (value, option) => {
    console.log(option["data-item"]?.walletName);

    if (value !== "newWallet") {
      setWalletsName(option["data-item"]?.walletName);
      setSelectedWalletAddress(value);
      disconnect();
      setTimeout(async () => {
        await handleConnect(option["data-item"]?.walletName);
      }, 2000);
    } else {
      console.log("new wallet");
      disconnect();
      OpenWallet();
      setSelectedWalletAddress("");
    }
    const addressToUse = value;

    const selectedItem = walletListDetails?.walletBalances?.find(
      (item) => item.walletAddress === addressToUse
    );
    console.log(selectedItem);
    setSelectedWalletDetails(selectedItem);
  };

  const navbarWalletAddress = address;

  useEffect(() => {
    console.log(walletListDetails);

    if (navbarWalletAddress && walletListDetails) {
      console.log(walletListDetails);
      setSelectedWalletAddress(navbarWalletAddress);
      const selectedWallet = walletListDetails?.walletBalances?.find(
        (wallet) => wallet.walletAddress === navbarWalletAddress
      );
      console.log(selectedWallet);
      if (selectedWallet) {
        setSelectedWalletDetails(selectedWallet);
      }
    } else if (
      !SelectedWalletAddress &&
      walletListDetails?.walletBalances?.length > 0
    ) {
      setSelectedWalletAddress(
        walletListDetails?.walletBalances[0]?.WalletAddress
      );
      setSelectedWalletDetails(walletListDetails?.walletBalances[0]);
    }
  }, [walletListDetails, navbarWalletAddress]);
  /////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (asset_list && SelectedStableCoin) {
      // Find the asset in the list that matches the value from Redux
      const matchingAsset = asset_list.find(
        (asset) => asset.label === SelectedStableCoin
      );
      if (matchingAsset) {
        // If a matching asset is found, select its value
        selectToken(matchingAsset.value);
      } else if (asset_list.length > 0) {
        // If no matching asset is found but the list is not empty, select the first asset by default
        selectToken(asset_list[0].value);
      }
    }
  }, [asset_list, SelectedStableCoin]);
  let formattedTokenBalance = toFixedWithoutRounding(
    Number(tokenBalance?.formatted || 0.0),
    2
  );
  const selectToken = (e) => {
    setAssetSelected(e);
    const selectedAsset = originalAssetList?.find(
      (asset) => asset?.contractAddress === e
    );
    if (selectedAsset) {
      setTokenLabel(selectedAsset?.tokenName);
      setDestinationAddress(selectedAsset?.destinationAddress);
    }
  };

  const [profileData, setProfileData] = useState({});
  useEffect(() => {
    const getData = async () => {
      const data = await GetProfile();
      setProfileData(data);
      console.log(data);
    };
    getData();
  }, []);
  // useEffect(() => {calculateFUBalances()}, [profileData])

  const calculateFUBalances = async () => {
    try {
      let cashBalance = SelectedWalletDetails?.walletBalance;
      console.log(cashBalance);
      let newFUBalances = parseFloat(cashBalance) - parseFloat(tokenAmount);
      console.log(newFUBalances, cashBalance, tokenAmount);
      // let formatted= newNumberformat(newFUBalances)
      if (newFUBalances < 0) {
        message.error("Insufficient balance to withdraw");
        return;
      }
      console.log(newFUBalances);
      await setFUBalances(newFUBalances); // Assuming newNumberformat is a function that formats the number as a string
      checkNetwork();
    } catch (error) {
      console.error("Error calculating FU balances:", error);
      setFUBalances("0");
    }
  };
  function getWalletLogo(walletID) {
    return walletLogos[walletID] || false;
  }
  return (
    <div className="card-style-deposit-withdraw-funds">
      <div className="card-style-deposit-withdraw-funds-header">
        Withdraw funds
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          paddingBottom: "32px",
        }}
      >
        <div
          style={{ fontWeight: "400", fontSize: "16px", lineHeight: "24px" }}
        >
          Amount to withdraw
        </div>
        <div>
          <Input
            style={{ width: "100%", height: "56px" }}
            className="new_input_normal_border"
            placeholder={`Enter sum`}
            value={tokenAmount}
            onKeyPress={(e) => {
              // Allow only digits
              if (!/^\d$/.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              const inputValue = e.target.value;
              // Regular expression to match whole numbers
              const regex = /^\d*$/;
              if (regex.test(inputValue)) {
                setTokenAmount(inputValue);
              }
            }}
          />
        </div>
        <div
          style={{
            fontWeight: "12px",
            fontWeight: "400",
            lineHeight: "16px",
            letterSpacing: "0.3px",
            color: "#161616B2",
          }}
        >
          Available funds on FU account, {SelectedStableCoin}:{" "}
          {newNumberformat(SelectedWalletDetails?.walletBalance || 0)}
        </div>
      </div>
      <div style={{ fontWeight: "400", fontSize: "16px", lineHeight: "24px" }}>
        Connected wallet
      </div>
      <div className="custom-select-box new_select_normal_border">
        <Select
          placeholder={"Select wallet"}
          className="filter-felid-width-style new_input_normal_border filter-select-style"
          dropdownStyle={{ zIndex: 1200 }}
          size={"large"}
          style={{ width: "100%", height: "56px" }}
          value={SelectedWalletAddress || null}
          onSelect={(value, option) => handleChange(value, option)}
          suffixIcon={<IoMdArrowDropdown color="black" size={14} />}
          //   suffixIcon={SelectedWalletAddress?<img src={checkIcons} alt='icon'/>:""}
          // disabled
          showSearch={false}
        >
          {walletListDetails?.walletBalances?.map((item) => (
            <Option
              key={item?.walletAddress}
              value={item.walletAddress}
              data-item={item} // Pass the entire item as a data attribute
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <>
                    {getWalletLogo(item?.walletName) ? (
                      <img
                        src={getWalletLogo(item?.walletName)}
                        style={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "50%",
                        }}
                        alt="wallet logo"
                      />
                    ) : (
                      <MetaMaskAvatar address={item?.walletAddress} size={24} />
                    )}
                  </>
                  <span
                    style={{
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    {shortenAddress(item?.walletAddress)}
                  </span>
                  <span style={{ fontSize: "12px" }}>{`${
                    !getWalletLogo(item?.walletName)
                      ? "(" + item?.walletName + ")"
                      : ""
                  }`}</span>
                </div>
                <div>
                  {/* <span style={{ paddingRight: "8px" }}>{`${newNumberformat(item.walletBalance)}`}</span> */}
                </div>
              </div>
            </Option>
          ))}
          <Option key={"newWallet"} value={"newWallet"}>
            <div
              className="div-navbar-wallet-connect"
              onClick={() => {}}
              style={{ width: "100%" }}
            >
              <div
                style={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "400",
                }}
              >
                Connect new wallet
              </div>
              <div>
                <img src={add_circle} alt="add circle" />
              </div>
            </div>
          </Option>
        </Select>
      </div>
      <div
        className=""
        style={{
          lineHeight: "24px",
          fontSize: "16px",
          fontWeight: "400",
          paddingBottom: "32px",
          paddingTop: "32px",
        }}
      >
        <Button
          style={{
            height: "52px",
            padding: "16px 48px",
            gap: "8px",
            width: "100%",
          }}
          onClick={() => {
            calculateFUBalances();
          }}
          className={
            !tokenAmount ? "new_button_filled_disabled" : "new_button_filled"
          }
        >
          Review and Confirm
        </Button>
      </div>
      <div
        className="network-label"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignContent: "center",
          gap: "8px",
        }}
      >
        <div>Powered by {network} Network</div>
        <div>
          <img src={netWorkIcon} alt="icon" />{" "}
        </div>
      </div>
    </div>
  );
};
const ConfirmWithdraw = ({
  SelectedWalletDetails,
  setSelectedWalletDetails,
  network,
  handleCopy,
  assetSelected,
  setAssetSelected,
  asset_list,
  setReviewState,
  tokenBalance,
  tokenAmount,
  chains,
  destinationAddress,
  tokenLabel,
  setTransitionHash,
  setTokenAmount,
}) => {
  const dispatch = useDispatch();
  const { connectAsync } = useConnect();
  const { address } = useAccount();
  const SelectedStableCoin = useSelector(
    (state) => state.global.SelectedStableCoin
  );
  const WithdrawalTransactionStatus = useSelector(
    (state) => state.global.WithdrawalTransactionStatus
  );
  const { open, close } = useWeb3Modal();
  const {
    data,
    error: transferError,
    isPending,
    isSuccess,
    writeContractAsync,
    variables,
  } = useWriteContract();
  let formattedTokenBalance = toFixedWithoutRounding(
    Number(tokenBalance?.formatted || 0.0),
    2
  );
  const [started, setStarted] = useState(false);
  const [errors, setErrors] = useState();
  const [completed, setCompleted] = useState(false);
  const { walletInfo } = useWalletInfo();
  // dispatch(setDepositTransactionStatus('success'))
  // useEffect(() => {
  //     console.log(transferError?.name, transferError)
  //     if (transferError?.name) {
  //         dispatch(setDepositTransactionStatus('failed'))
  //     }
  // }, [transferError])
  const TwoFactorAuthenticationStatus = useSelector(
    (state) => state.global.TwoFactorAuthenticationStatus
  );

  const [open2FAModal, setOpen2FAModal] = useState(false);
  const [open2FAModalSetup, setOpen2FAModalSetup] = useState(false);
  const handlePayment = async () => {
    console.log("Payment started:", TwoFactorAuthenticationStatus);
    try {
      if (TwoFactorAuthenticationStatus) {
        console.log("2FA enabled");
        setOpen2FAModal(true);
      } else {
        setOpen2FAModalSetup(true);
        // setErrors('')
        // setStarted(true)
        // if (!address) {
        //     // await connectAsync({ chainId: chains[0]?.id, connector: injected() })
        //   return  open()
        // }
        // sendTransactionToBackend()
        // setCompleted(true)
      }

      console.log(data);
    } catch (err) {
      setLoader(false);
      console.log(err);
      console.log(err?.code);
      setStarted(false);
      setErrors("Payment failed. Please try again.");
    }
  };
  const onModalCallBack = () => {
    setErrors("");
    setStarted(true);
    if (!address) {
      // await connectAsync({ chainId: chains[0]?.id, connector: injected() })
      return open();
    }
    sendTransactionToBackend();
    setCompleted(true);
  };
  const [loaderTransaction, setLoaderTransaction] = useState(false);
  const sendTransactionToBackend = async (data) => {
    setLoader(true);
    setLoaderTransaction(true);
    try {
      const body = {
        chainId: chains[0]?.id,
        chainName: chains[0]?.name,
        token: tokenLabel,
        amount: tokenAmount,
        sourceAddress: destinationAddress, // firblock address
        destinationAddress: address, //user Address
        fiatCurrency: "USD",
        txnType: "WITHDRAW",
        walletName: walletInfo?.name,
      };
      //   return console.log(body)
      const response = await clientApi.post(
        `/api/investor/withdraw-details`,
        body
      );
      if (response?.status === 200) {
        dispatch(setWithdrawalTransactionStatus("success"));
        setStarted(false);
        setLoader(false);
        setLoaderTransaction(false);
      }
    } catch (error) {
      dispatch(setWithdrawalTransactionStatus("failed"));
      setLoader(false);
      setStarted(false);
      setLoaderTransaction(false);
      console.log(error);
      HandelError(error);
    }
  };
  const BoldDataComponent = ({ label, value }) => {
    return (
      <>
        <div className="deposit-container-body-data deposit-container-body-data-bold">
          <div>{label}</div>
          <div>{newNumberformat(value)}</div>
        </div>
      </>
    );
  };
  const NormalDataComponent = ({ label, value }) => {
    return (
      <>
        <div className="deposit-container-body-data ">
          <div className="deposit-container-body-data-label">{label}</div>
          <div className="deposit-container-body-data-label">{value}</div>
        </div>
      </>
    );
  };
  const [profileData, setProfileData] = useState({});
  useEffect(() => {
    const getData = async () => {
      const data = await GetProfile();
      setProfileData(data);
      console.log(data);
    };
    getData();
  }, []);
  useEffect(() => {
    calculateFUBalances();
    CalculateWalletBalances();
  }, [profileData]);
  const [FUBalances, setFUBalances] = useState("");
  const calculateFUBalances = async () => {
    try {
      let cashBalance = SelectedWalletDetails?.walletBalance;
      console.log(cashBalance);
      let newFUBalances = parseFloat(cashBalance) - parseFloat(tokenAmount);
      console.log(newFUBalances);
      let formatted = newNumberformat(newFUBalances || 0);
      console.log(formatted);
      setFUBalances(formatted); // Assuming newNumberformat is a function that formats the number as a string
    } catch (error) {
      console.error("Error calculating FU balances:", error);
      setFUBalances("0");
    }
  };
  let WalletBalances = "";
  const CalculateWalletBalances = () => {
    console.log(tokenBalance, tokenAmount);
    WalletBalances =
      parseFloat(tokenBalance?.formatted) + parseFloat(tokenAmount);
    console.log(WalletBalances);
    return newNumberformat(WalletBalances);
  };
  console.log(WithdrawalTransactionStatus);
  const Childe = () => {
    const formatAddress = (address) => {
      if (!address) return "";
      return `${address.slice(0, 6)}...${address.slice(-6)}`;
    };
    return (
      <div>
        {formatAddress(address)}&nbsp;{`(${walletInfo?.name})`}
      </div>
    );
  };
  /////////////////////////////////////////////////////

  const [totp, setTotp] = useState("");
  const [validationError, setValidationError] = useState({
    error: false,
    message: "",
    exists: false,
  });
  const [loader, setLoader] = useState(false);
  const veriff2fa = async () => {
    setLoader(true);
    try {
      const data = await clientApi.post("/api/investor/2fa/validate", {
        totp: totp,
      });
      console.log(data);
      if (data.status === 200) {
        setTotp("");
        // message.success("Two factor authentication disable");
        // localStorage.setItem("2FAStatus", "false");
        setErrors("");
        setStarted(true);
        if (!address) {
          // await connectAsync({ chainId: chains[0]?.id, connector: injected() })
          return open();
        }
        sendTransactionToBackend();
        setCompleted(true);
        handelClose();
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      if (
        error?.response?.data?.validation?.fieldErrors?.totp === "INVALID_VALUE"
      ) {
        setValidationError({
          error: true,
          message: "Invalid code",
          exists: false,
        });
      } else {
        HandelError(error);
      }
    }
  };
  const handelClose = () => {
    setOpen2FAModal(false);
    setTotp("");
    setLoader(false);
    setValidationError({
      error: false,
      message: "",
      exists: false,
    });
  };
  /////////////////////////////////////////////////////
  return (
    <>
      {WithdrawalTransactionStatus === "first" && (
        <>
          <div className="card-style-deposit-withdraw-funds">
            <div className="card-style-deposit-withdraw-funds-header">
              Withdraw funds
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                paddingBottom: "32px",
              }}
            >
              <NormalDataComponent
                label={"Stablecoin"}
                value={SelectedStableCoin}
              />
              <NormalDataComponent
                label={"Connected wallet"}
                value={<Childe />}
              />
              <NormalDataComponent
                label={`Linked wallet balance after withdraw, ${SelectedStableCoin}`}
                value={FUBalances}
              />
              <NormalDataComponent
                label={`Connected wallet balance after withdraw, ${SelectedStableCoin}`}
                value={CalculateWalletBalances()}
              />
              <BoldDataComponent
                label={" Withdraw amount"}
                value={tokenAmount}
              />
            </div>
            <div className="">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "24px",
                  width: "100%",
                }}
              >
                <Button
                  style={{
                    height: "52px",
                    padding: "16px 48px",
                    gap: "8px",
                    width: "80px",
                  }}
                  onClick={() => {
                    setReviewState(false);
                  }}
                  disabled={loaderTransaction}
                  className="new_button_outline  "
                >
                  Back
                </Button>
                <Button
                  style={{
                    height: "52px",
                    padding: "16px 48px",
                    width: "100%",
                  }}
                  disabled={loaderTransaction}
                  onClick={() => {
                    handlePayment();
                  }}
                  className="new_button_filled"
                >
                  Confirm withdraw {newNumberformat(tokenAmount)}
                </Button>
              </div>
              <div
                className="network-label"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignContent: "center",
                  gap: "8px",
                  paddingTop: "32px",
                }}
              >
                <div>Powered by {network} Network</div>
                <div>
                  <img src={netWorkIcon} alt="icon" />{" "}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {WithdrawalTransactionStatus === "failed" && (
        <>
          <ErrorMessagesCard
            tokenAmount={tokenAmount}
            setReviewState={setReviewState}
            handlePayment={handlePayment}
            isPending={isPending}
            setTokenAmount={setTokenAmount}
          />
        </>
      )}
      {WithdrawalTransactionStatus === "success" && (
        <>
          <SuccessMessagesCard
            tokenAmount={tokenAmount}
            setReviewState={setReviewState}
            handlePayment={handlePayment}
            isPending={isPending}
            setTokenAmount={setTokenAmount}
          />
        </>
      )}
      <Modal
        className="investment-modal"
        open={open2FAModal}
        centered
        closable={false}
        footer={null}
        width={560}
        onCancel={() => handelClose()}
        style={{}}
      >
        <div style={{ padding: "8px" }}>
          <div
            className="modal-close-icon"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <img src={close_icon} alt="close" onClick={() => handelClose()} />
          </div>
          <div style={{ padding: "0px 26px 32px 26px" }}>
            <div className="profile-modal-header">
              Verify Two-factor authentication
            </div>
            <div style={{ height: "32px" }}></div>
            <SignUpInputBox
              label={"Enter authentication code"}
              isRequired={true}
              placeholder={"6-digit code"}
              value={totp}
              onChange={(e) => {
                setTotp(e.target.value);
              }}
              validationError={validationError}
              regexPattern="^[0-9]*$"
              maxLength={6}
            />
            <div style={{ height: "40px" }}></div>
            <Button
              className={`${
                totp.length === 6
                  ? "new_button_filled"
                  : "new_button_filled_disabled"
              }`}
              // className="new_button_filled hight-56"
              style={{ width: "100%", height: "56px" }}
              htmlType="submit"
              disabled={totp.length !== 6}
              loading={loader}
              onClick={() => veriff2fa()}
            >
              Verify
            </Button>
          </div>
        </div>
      </Modal>

      <Modal2faWithdraw
        isOpen={open2FAModalSetup}
        setIsOpen={setOpen2FAModalSetup}
        callBack={onModalCallBack}
      />
    </>
  );
};

const NormalDataComponent = ({ label, value }) => {
  return (
    <>
      <div
        className="deposit
        
        deposit-container-body-data "
      >
        <div className="deposit-container-body-data-label">{label}</div>
        <div className="deposit-container-body-data-label">{value}</div>
      </div>
    </>
  );
};
const NormalDataComponentSuccess = ({ label, value }) => {
  return (
    <>
      <div className="deposit-container-body-data ">
        <div className="deposit-container-body-data-label">{label}</div>
        <div
          className="deposit-container-body-data-label"
          style={{ color: "var(--secondary-color)" }}
        >
          {value}
        </div>
      </div>
    </>
  );
};
const BoldDataComponent = ({ label, value }) => {
  return (
    <>
      <div className="deposit-container-body-data deposit-container-body-data-bold">
        <div>{label}</div>
        <div>{value}</div>
      </div>
    </>
  );
};

const formatDate = (date) => {
  return dayjs(date).format("D MMMM YYYY, HH:mm");
};
const ErrorMessagesCard = ({
  transferError,
  tokenAmount,
  setReviewState,
  handlePayment,
  isPending,
  setTokenAmount,
}) => {
  const dispatch = useDispatch();
  const SelectedStableCoin = useSelector(
    (state) => state.global.SelectedStableCoin
  );
  const GoBack = () => {
    dispatch(setDepositTransactionStatus("first"));
    dispatch(setWithdrawalTransactionStatus("first"));
    setReviewState(false);
    setTokenAmount("");
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          padding: "32px 24px",
          height: "558px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={errorIcon} alt="icon" style={{ height: "64px" }} />
        </div>
        <div
          className="deposit-container-body-data-bold"
          style={{
            fontSize: "24px",
            lineHeight: "36px",
            textAlign: "center",
            paddingBottom: "32px",
          }}
        >
          Something went wrong with your withdraw
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            paddingBottom: "32px",
            width: "100%",
          }}
        >
          <NormalDataComponent
            label={`Withdraw date and time`}
            value={formatDate(dayjs())}
          />
          <NormalDataComponent
            label={"Stablecoin"}
            value={SelectedStableCoin}
          />
          <NormalDataComponent
            label={`Withdraw amount`}
            value={newNumberformat(tokenAmount)}
          />
          <div className="custom-hr">
            <hr className=" hr-style-2 "></hr>
          </div>
          <BoldDataComponent
            label={"Status"}
            value={<span style={{ color: "var(--error-color)" }}>Error</span>}
          />
        </div>
        <div
          className="deposit-container-body-data-bold"
          style={{ color: "var(--error-color)" }}
        >
          Transfer aborted, funds returned to FU account!
        </div>
        <div style={{ paddingTop: "72px" }}>
          <Button
            style={{
              height: "52px",
              padding: "16px 48px",
              width: "100%",
            }}
            disabled={isPending}
            onClick={() => {
              handlePayment();
            }}
            className="new_button_filled"
          >
            Try withdraw {SelectedStableCoin} {newNumberformat(tokenAmount)}{" "}
            again!
          </Button>
          <div
            style={{
              paddingTop: "24px",
              display: "flex",
              justifyContent: "center",
              paddingBottom: "24px",
            }}
          >
            <div
              className="new-web-three-cancel-button"
              onClick={() => {
                if (!isPending) GoBack();
              }}
              style={{
                cursor: isPending ? "not-allowed" : "pointer",
                opacity: isPending ? 0.5 : 1,
              }}
            >
              Cancel
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const SuccessMessagesCard = ({
  transferError,
  tokenAmount,
  setReviewState,
  handlePayment,
  isPending,
  setTokenAmount,
}) => {
  const dispatch = useDispatch();
  const SelectedStableCoin = useSelector(
    (state) => state.global.SelectedStableCoin
  );
  const GoBack = () => {
    dispatch(setDepositTransactionStatus("first"));
    dispatch(setWithdrawalTransactionStatus("first"));
    setReviewState(false);
    setTokenAmount("");
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          padding: "32px 24px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={successIcon} alt="icon" style={{ height: "64px" }} />
        </div>
        <div
          className="deposit-container-body-data-bold"
          style={{
            fontSize: "24px",
            lineHeight: "36px",
            textAlign: "center",
            paddingBottom: "32px",
          }}
        >
          Your Withdraw successfully initiated
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            paddingBottom: "32px",
            width: "100%",
          }}
        >
          <NormalDataComponentSuccess
            label={`Withdrawal date and time`}
            value={formatDate(dayjs())}
          />
          <NormalDataComponentSuccess
            label={"Stablecoin"}
            value={SelectedStableCoin}
          />
          <NormalDataComponentSuccess
            label={`Withdraw amount`}
            value={newNumberformat(tokenAmount)}
          />
          <div className="custom-hr">
            <hr className=" hr-style-2 "></hr>
          </div>
          <BoldDataComponent
            label={"Status"}
            value={
              <span style={{ color: "var(--success-color)" }}>Success</span>
            }
          />
        </div>
        <div
          className="deposit-container-body-data-bold"
          style={{ fontSize: "14px", textAlign: "center" }}
        >
          Your stable coins should arrive shortly, depending on network load.
          <br></br> We’ll notify you by email.
        </div>
        <div
          style={{
            paddingTop: "24px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            className="new-web-three-deposit-again-button"
            onClick={() => {
              GoBack();
            }}
          >
            Withdraw again
          </div>
        </div>
      </div>
    </>
  );
};
