import { Button, Modal } from "antd";
import close_icon from "../../Assets/images/IconButton.svg";
import {
  newNumberformat,
  newNumberformatWithFraction,
} from "../../Helper/HelperFunction";

export default function CancelModal({ isOpen, handleCancel, callBack, data }) {
  return (
    <>
      <Modal
        className="investment-modal"
        open={isOpen}
        centered
        closable={false}
        footer={null}
        width={560}
        onCancel={() => handleCancel()}
        style={{}}
      >
        <div style={{ padding: "8px" }}>
          <div
            className="modal-close-icon"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <img
              src={close_icon}
              alt="close"
              onClick={() => handleCancel()}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div style={{ padding: "0px 26px 32px 26px" }}>
            <div className="invest-modal-header">Cancel sell</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "48px",
                paddingBottom: "32px",
              }}
            >
              <div>
                <div className="invest-modal-subheader">Loan ID</div>
                <div className="invest-modal-subheader-value">
                  {data?.loanId}
                </div>
              </div>
              <div>
                <div className="invest-modal-subheader">Start sell date</div>
                <div className="invest-modal-subheader-value">
                  {data?.investmentDate}
                </div>
              </div>
              <div>
                <div className="invest-modal-subheader">
                  Price and discount/premium
                </div>
                <div
                  className="invest-modal-subheader-value"
                  style={{ display: "flex", flexDirection: "row", gap: "8px" }}
                >
                  <div>
                    {newNumberformatWithFraction(
                      data?.smOfferPrincipalAvailable
                    )}
                  </div>
                  <div
                    className={`status-box-code status-box-code-label ${
                      data?.smDiscountOrPremiumPercent > 0
                        ? "status-box-code-background-error"
                        : data?.smDiscountOrPremiumPercent < 0
                        ? "status-box-code-background-success"
                        : data?.smDiscountOrPremiumPercent == 0
                        ? "status-box-code-background-zero"
                        : ""
                    }`}
                  >
                    {data?.smDiscountOrPremiumPercent == 0
                      ? "0,0"
                      : newNumberformat(data?.smDiscountOrPremiumPercent)}{" "}
                    %
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ paddingTop: "40px" }}>
              <div className="col-md-12">
                <Button
                  block
                  htmlType="submit"
                  // loading={loader}
                  className="new_button_filled_cancel"
                  style={{ height: "48px" }}
                  onClick={() => {
                    callBack();
                  }}
                >
                  Cancel sell
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
