// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.investment-modal .ant-modal-content {
  padding: 0px !important;
  border-radius: 6px !important;
  box-shadow: 0px 32px 40px 0px rgba(36, 36, 36, 0.1490196078);
  box-shadow: 0px 26px 32px -24px rgba(36, 36, 36, 0.3490196078);
}

.investment-modal .ant-modal-root .ant-modal-mask {
  background-color: rgba(36, 36, 36, 0.7490196078) !important;
}

/* .modal-close-icon:hover {
  padding: 8px;
  gap: 0px;
  border-radius: 100%;
  opacity: 0;
  color: #161616B2;
} */
/* Pressed state */
/* .modal-close-icon:active {
  padding: 8px;
  gap: 0px;
  border-radius: 100%;
  opacity: 0;
  color: #24242426;
} */
.invest-modal-header {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  color: #242424;
  padding-bottom: 16px;
}

.invest-modal-subheader {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: rgba(22, 22, 22, 0.6980392157);
}

.invest-modal-subheader-value {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #242424;
}

.ant-table-column-sorters {
  display: flex;
  flex-direction: column-reverse;
  gap: 0.25rem;
}

.non-sorter-columns {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/PrimaryMarketTable/style.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,6BAAA;EACA,4DAAA;EACA,8DAAA;AACF;;AACA;EACE,2DAAA;AAEF;;AAAA;;;;;;GAAA;AAQA,kBAAA;AACA;;;;;;GAAA;AAOA;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,oBAAA;AAEF;;AAAA;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,qCAAA;AAGF;;AADA;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAIF;;AADA;EACE,aAAA;EACA,8BAAA;EACA,YAAA;AAIF;;AADA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,mBAAA;AAIF","sourcesContent":[".investment-modal .ant-modal-content {\n  padding: 0px !important;\n  border-radius: 6px !important;\n  box-shadow: 0px 32px 40px 0px #24242426;\n  box-shadow: 0px 26px 32px -24px #24242459;\n}\n.investment-modal .ant-modal-root .ant-modal-mask {\n  background-color: #242424bf !important;\n}\n/* .modal-close-icon:hover {\n  padding: 8px;\n  gap: 0px;\n  border-radius: 100%;\n  opacity: 0;\n  color: #161616B2;\n} */\n\n/* Pressed state */\n/* .modal-close-icon:active {\n  padding: 8px;\n  gap: 0px;\n  border-radius: 100%;\n  opacity: 0;\n  color: #24242426;\n} */\n.invest-modal-header {\n  font-family: Inter;\n  font-size: 24px;\n  font-weight: 700;\n  line-height: 36px;\n  text-align: left;\n  color: #242424;\n  padding-bottom: 16px;\n}\n.invest-modal-subheader {\n  font-family: Inter;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px;\n  text-align: left;\n  color: #161616b2;\n}\n.invest-modal-subheader-value {\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 24px;\n  text-align: left;\n  color: #242424;\n}\n\n.ant-table-column-sorters {\n  display: flex;\n  flex-direction: column-reverse;\n  gap: 0.25rem;\n}\n\n.non-sorter-columns {\n  display: flex;\n  flex-direction: column;\n  padding-top: 1rem;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
