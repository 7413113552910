import React, { useEffect, useState } from "react";
import "./style.scss";
import { Space } from "antd";
import dayjs from "dayjs";
import CustomDatePicker from "../../../UI-Library/DatePicker/CustomDatePicker";

export default function StatementDatePicker({
  filter,
  setFilter,
  suffixIcon,
  className,
}) {
  const [startValue, setStartValue] = useState(dayjs(filter?.dateFrom));
  const [endValue, setEndValue] = useState(dayjs(filter?.dateTo));
  const [endOpen, setEndOpen] = useState(false);

  const disabledStartDate = (startValue) => {
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  const disabledEndDate = (endValue) => {
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() < startValue.valueOf();
  };

  const handleStartChange = (value) => {
    setStartValue(value);
    setFilter({ ...filter, dateFrom: dayjs(value).format("YYYY-MM-DD") });
  };

  const handleEndChange = (value) => {
    setEndValue(value);
    setFilter({ ...filter, dateTo: dayjs(value).format("YYYY-MM-DD") });
  };

  const handleStartOpenChange = (open) => {
    if (!open) {
      setEndOpen(true);
    }
  };

  const handleEndOpenChange = (open) => {
    setEndOpen(open);
  };

  useEffect(() => {
    if (filter.dateFrom && filter.dateTo) {
      setStartValue(dayjs(filter.dateFrom));
      setEndValue(dayjs(filter.dateTo));
    }
  }, [filter]);

  return (
    <Space direction="vertical" size={12}>
      <>
        <CustomDatePicker
          disabledDate={disabledStartDate}
          format="YYYY-MM-DD"
          value={startValue}
          placeholder="Start"
          onChange={handleStartChange}
          onOpenChange={handleStartOpenChange}
          showNow={false}
          allowClear={false}
          popupClassName="noFooterTimePick"
          suffixIcon={<img src={suffixIcon} alt="icon" />}
          popupStyle={{ zIndex: 999 }}
          renderExtraFooter={() => null}
          className={className}
        />
        <CustomDatePicker
          disabledDate={disabledEndDate}
          format="YYYY-MM-DD"
          value={endValue}
          placeholder="End"
          onChange={handleEndChange}
          open={endOpen}
          onOpenChange={handleEndOpenChange}
          showNow={false}
          allowClear={false}
          popupClassName="noFooterTimePick"
          suffixIcon={<img src={suffixIcon} alt="icon" />}
          popupStyle={{ zIndex: 999 }}
          renderExtraFooter={() => null}
          className={className}
        />
      </>
    </Space>
  );
}
