import api from "./api";

const fetchAccountStatementData = async (
  url,
  params,
  responseType = "json"
) => {
  try {
    const response = await api.post(url, params, { responseType });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAccountStatementData = (params) =>
  fetchAccountStatementData("/api/investor/query-account-statement", params);

export const getAccountStatementPDFData = (params) =>
  fetchAccountStatementData(
    "/api/investor/download-account-statement-pdf",
    params,
    "arraybuffer"
  );

export const getAccountStatementExcelData = (params) =>
  fetchAccountStatementData(
    "/api/investor/download-account-statement",
    params,
    "arraybuffer"
  );
