import React, { useEffect, useState } from "react";
import "./MyPortfolio.css";
import { GetProfile, HandelError, clientApi } from "../../Utils/AxiosCall";
import countries from "i18n-iso-countries";
import MyPortfolioFilter from "../../Components/MyPortfolioFilter/MyPortfolioFilter";
import { newNumberformat } from "../../Helper/HelperFunction";
import { useSelector } from "react-redux";
import HeaderLabelTwo from "../../Components/Components/Atom/HeaderLabel/HeaderLabelTwo";
import PageHeader from "../../Components/Components/Molecule/PageHeader/PageHeader";
import PortfolioIcon from "../../Assets/images/Portfolio-icon.svg";
import chartIcon from "../../Assets/images/finance-chart-icon.svg";
import excalIcon from "../../Assets/images/excal-icon-port.svg";
import MyPortfolioFilterNew from "../../Components/MyPortfolioFilter/MyPortfolioFilterNew";
import { transformData } from "../../Helper/SelectBoxDataGenrater";

import Tables from "./Table/Table";
import ChartComponent from "./Chart";
import {
  getPortfolioChartData,
  getPortfolioData,
} from "../../services/portfolioService";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default function MyPortfolio() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [data, setData] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currencyCode = useSelector((state) => state.global.SelectedStableCoin);
  const [filters, setFilters] = useState({
    autoInvestId: null,
    collectionStatuses: [],
    countries: [],
    currencyCode: currencyCode,
    extensions: null,
    forSale: false,
    interestRatePercentFrom: null,
    interestRatePercentTo: null,
    investStrategyId: null,
    loanId: null,
    originators: [],
    products: [],
    remainingTermInDaysFrom: null,
    remainingTermInDaysTo: null,
    showActive: false,
    showClosed: false,
    walletAddresses: [],
    loanDurationInDaysTo: null,
    loanDurationInDaysFrom: null,
  });
  const [chartData, setChartData] = useState({});

  const filter = {};
  const CheckFilter = async () => {
    try {
      if (filters.loanId) filter.loanId = filters.loanId;
      if (filters.collectionStatuses.length > 0)
        filter.collectionStatuses = filters.collectionStatuses;
      if (filters.countries.length > 0) filter.countries = filters.countries;
      if (filters.currencyCode) filter.currencyCode = filters.currencyCode;
      if (filters.extensions !== null) filter.extensions = filters.extensions;
      if (filters.interestRatePercentFrom !== null)
        filter.interestRatePercentFrom = filters.interestRatePercentFrom;
      if (filters.interestRatePercentTo !== null)
        filter.interestRatePercentTo = filters.interestRatePercentTo;
      if (filters.showActive !== null) filter.showActive = filters.showActive;
      if (filters.showClosed !== null) filter.showClosed = filters.showClosed;
      if (filters.remainingTermInDaysFrom !== null)
        filter.remainingTermInDaysFrom = filters.remainingTermInDaysFrom;
      if (filters.remainingTermInDaysTo !== null)
        filter.remainingTermInDaysTo = filters.remainingTermInDaysTo;
      if (filters.autoInvestId) filter.autoInvestId = filters.autoInvestId;
      if (filters.investStrategyId)
        filter.investStrategyId = filters.investStrategyId;
      if (filters.forSale !== null) filter.forSale = filters.forSale;
      if (filters.products.length > 0) filter.products = filters.products;
      if (filters.originators.length > 0)
        filter.originators = filters.originators;
      if (filters.walletAddresses.length > 0)
        filter.walletAddresses = filters.walletAddresses;
      if (filters.loanDurationInDaysFrom !== null)
        filter.loanDurationInDaysFrom = filters.loanDurationInDaysFrom;
      if (filters.loanDurationInDaysTo !== null)
        filter.loanDurationInDaysTo = filters.loanDurationInDaysTo;

      return filter;
    } catch (error) {
      console.log(error);
    }
  };
  const [labelStatus, setLabelStatus] = useState({
    active: false,
    forSale: false,
    closed: false,
  });
  const GetMyPortfolio = async () => {
    setLoader(true);
    await CheckFilter();
    // if (!filter.currencyCode) {
    filter.currencyCode = currencyCode;
    // }
    let newFilterData = { ...filter };
    if (
      filter.showActive === false &&
      filter.showClosed === false &&
      filter.forSale === false
    ) {
      const { showActive, showClosed, forSale, ...remainingFilters } =
        newFilterData;
      newFilterData = remainingFilters;
    }
    if (
      filter.showActive === true &&
      filter.showClosed === true &&
      filter.forSale === true
    ) {
      const { showActive, showClosed, forSale, ...remainingFilters } =
        newFilterData;
      newFilterData = remainingFilters;
    }

    try {
      //   const response = await clientApi.post(
      //     "/api/investor/query-my-investments",
      //     { filter: newFilterData, page, pageSize }
      //   );

      const data = await getPortfolioData({
        filter: newFilterData,
        page,
        pageSize,
      });

      setData(data);
      setTotalItems(data?.total);
      setLabelStatus({
        active: filters.showActive,
        forSale: filters.forSale,
        closed: filters.showClosed,
      });
      if (isModalOpen) {
        setIsModalOpen(false);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      HandelError(error);
    }
    try {
      //   const response = await clientApi.post(
      //     "/api/investor/portfolio-statistics",
      //     { filter: newFilterData, page, pageSize }
      //   );

      const data = await getPortfolioChartData({
        filter: newFilterData,
        page,
        pageSize,
      });

      setChartData(data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      HandelError(error);
    }
  };
  const exportData = async () => {
    setLoader(true);
    if (!filter.currencyCode) {
      filter.currencyCode = currencyCode;
    }
    try {
      const response = await clientApi.post(
        "/api/investor/download-my-investments",
        { filter: filter, page, pageSize },
        { responseType: "arraybuffer" }
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const filename = response.headers["x-filename"];
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
      HandelError(error);
    } finally {
      setLoader(false);
    }
  };
  const [counts, setCounts] = useState({
    active: 0,
    forSale: 0,
    closed: 0,
  });
  const [accountOverviewData, setAccountOverviewData] = useState({});
  const GetAccountOverview = async () => {
    try {
      // EUR || USD
      const response = await clientApi.post("/api/investor/account-summary", {
        currencyCode: currencyCode,
      });
      console.log(response.data);
      if (response?.status === 200) {
        setAccountOverviewData(response.data);
      }
    } catch (error) {
      HandelError(error);
    }
  };
  // const GetTheCount = async () => {
  //   try {
  //     const activeResponse = await clientApi.post(
  //       "/api/investor/query-my-investments",
  //       {
  //         filter: {
  //           currencyCode: currencyCode,
  //           showActive: true,
  //           forSale: false,
  //           showClosed: false,
  //         },
  //         page: 1,
  //         pageSize: 1,
  //       }
  //     );
  //     setCounts((counts) => ({
  //       ...counts,
  //       active: activeResponse?.data?.total || 0,
  //     }));
  //   } catch (error) {
  //     // HandleError(error);
  //   }
  //   try {
  //     const forSaleResponse = await clientApi.post(
  //       "/api/investor/query-my-investments",
  //       {
  //         filter: {
  //           currencyCode: currencyCode,
  //           showActive: false,
  //           forSale: true,
  //           showClosed: false,
  //         },
  //         page: 1,
  //         pageSize: 1,
  //       }
  //     );
  //     setCounts((counts) => ({
  //       ...counts,
  //       forSale: forSaleResponse?.data?.total || 0,
  //     }));
  //   } catch (error) {
  //     // HandleError(error);
  //   }
  //   try {
  //     const closedResponse = await clientApi.post(
  //       "/api/investor/query-my-investments",
  //       {
  //         filter: {
  //           currencyCode: currencyCode,
  //           showActive: false,
  //           forSale: false,
  //           showClosed: true,
  //         },
  //         page: 1,
  //         pageSize: 1,
  //       }
  //     );
  //     setCounts((counts) => ({
  //       ...counts,
  //       closed: closedResponse?.data?.total || 0,
  //     }));
  //   } catch (error) {
  //     // HandleError(error);
  //   }
  // };
  const [referenceData, setReferenceData] = useState({});
  const [countries_list, setCountries_list] = useState([]);
  const [search_listener, setSearch_listener] = useState(0);
  const [originators_list, setOriginators_list] = useState([]);
  const [collectionStatuses_list, setCollectionStatuses_list] = useState([]);
  const [products_list, setProducts_list] = useState([]);
  const GetReferenceData = async () => {
    try {
      const response = await clientApi.post("/api/investor/reference-data");
      if (response?.status === 200) {
        console.log(response.data);
        setReferenceData(response.data);
        const countries = await transformData(response.data.countries);
        const originators = await transformData(response.data.originators);
        const collectionStatuses = await transformData(
          response.data.collectionStatuses
        );
        const products = await transformData(response.data.products);
        try {
          await setCountries_list(countries);
          await setOriginators_list(originators);
          await setCollectionStatuses_list(collectionStatuses);
          await setProducts_list(products);
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      HandelError(error);
    }
  };
  useEffect(() => {
    // GetMyPortfolio();
    // ListALLData();
    GetProfile();
    // GetTheCount();
  }, []);

  useEffect(() => {
    GetAccountOverview();
    GetMyPortfolio();
    GetReferenceData();
  }, [currencyCode]);

  const SubComponent = ({ data }) => {
    return (
      <>
        <HeaderLabelTwo
          HeaderLabel="Deployed balance"
          data={
            accountOverviewData?.principalInvested
              ? newNumberformat(accountOverviewData?.principalInvested)
              : 0
          }
          defaultValue="0"
        />
        <HeaderLabelTwo
          HeaderLabel="Ongoing"
          defaultValue="0"
          data={newNumberformat(data?.activeCount)}
        />
        <HeaderLabelTwo
          HeaderLabel="For sale"
          defaultValue="0"
          data={newNumberformat(data?.forSaleCount)}
        />
        <HeaderLabelTwo
          HeaderLabel="Closed/Sold"
          defaultValue="0"
          data={newNumberformat(data?.closedCount)}
        />
      </>
    );
  };
  const TableHeaderLeftValue = ({ label, data }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "4px",
          alignItems: "center",
        }}
      >
        <div className="new-table-container-header-label">{label}</div>
        <div className="new-table-container-header-bold">
          {newNumberformat(data || 0)}
        </div>
      </div>
    );
  };
  const TableHeaderRightValue = ({ label, imguri, onClick }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "4px",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => onClick()}
      >
        <div className="new-table-container-header-label">
          <img
            src={imguri}
            style={{ width: "24px", height: "24px" }}
            alt="icon"
          />
        </div>
        <div className="new-table-container-header-link">{label}</div>
      </div>
    );
  };
  const [openChart, setOpenChart] = useState(false);
  const clearFlitter = async () => {
    await setFilters({
      autoInvestId: null,
      collectionStatuses: [],
      countries: [],
      currencyCode: filters?.currencyCode || currencyCode,
      extensions: null,
      forSale: false,
      interestRatePercentFrom: null,
      interestRatePercentTo: null,
      investStrategyId: null,
      loanId: null,
      originators: [],
      products: [],
      remainingTermInDaysFrom: null,
      remainingTermInDaysTo: null,
      showActive: false,
      showClosed: false,
      walletId: "",
      walletAddresses: [],
      loanDurationInDaysTo: null,
      loanDurationInDaysFrom: null,
    });
  };

  const ResetData = async () => {
    setPage(1);
    if (!filter.currencyCode) {
      filter.currencyCode = currencyCode;
    }
    try {
      const response = await clientApi.post(
        "/api/investor/query-my-investments",
        { filter: { currencyCode: currencyCode }, page, pageSize }
      );
      if (response?.status === 200) {
        console.log(response.data);
        setData(response.data);
        setTotalItems(response?.data?.total);
        setLabelStatus({
          showActive: false,
          forSale: false,
          showClosed: false,
        });
        if (isModalOpen) {
          setIsModalOpen(false);
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      HandelError(error);
    }
    try {
      const response = await clientApi.post(
        "/api/investor/portfolio-statistics",
        {
          filter: {
            currencyCode: currencyCode,
            showActive: true,
            forSale: false,
            showClosed: false,
          },
          page,
          pageSize,
        }
      );
      if (response?.status === 200) {
        setFilters({
          ...filters,
          showActive: false,
          showClosed: false,
          forSale: false,
        });
        setLabelStatus({ showActive: true, forSale: false, showClosed: false });
        console.log(response.data);
        setChartData(response.data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      HandelError(error);
    }
    clearFlitter();
  };
  const ListALLData = async () => {
    setPage(1);
    try {
      const response = await clientApi.post(
        "/api/investor/query-my-investments",
        { filter: { currencyCode: currencyCode }, page, pageSize }
      );
      if (response?.status === 200) {
        console.log(response.data);
        setData(response.data);
        setTotalItems(response?.data?.total);
        setFilters({
          ...filters,
          showActive: false,
          showClosed: false,
          forSale: false,
        });
        setLabelStatus({
          showActive: false,
          forSale: false,
          showClosed: false,
        });
        if (isModalOpen) {
          setIsModalOpen(false);
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      HandelError(error);
    }
    try {
      const response = await clientApi.post(
        "/api/investor/portfolio-statistics",
        { filter: { currencyCode: currencyCode }, page, pageSize }
      );
      if (response?.status === 200) {
        console.log(response.data);
        setFilters({
          ...filters,
          showActive: false,
          showClosed: false,
          forSale: false,
        });
        setChartData(response.data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      HandelError(error);
    }
  };
  return (
    <div>
      <>
        <PageHeader
          title="Portfolio"
          image={PortfolioIcon}
          subComponent={<SubComponent data={data} />}
        />

        <div style={{ backgroundColor: "#FFFFFFA6", width: "100%" }}>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <div
              style={{ padding: "0px 48px", width: "100%", maxWidth: "1700px" }}
            >
              <MyPortfolioFilterNew
                filters={filters}
                setFilters={setFilters}
                countries_list={countries_list}
                products_list={products_list}
                collectionStatuses_list={collectionStatuses_list}
                originators_list={originators_list}
                handleSearch={GetMyPortfolio}
                totalCount={data}
                clearFlitter={clearFlitter}
                ResetData={ResetData}
                ListALLData={ListALLData}
                labelStatus={labelStatus}
              />
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: "white", marginTop: "1px" }}>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <div
              style={{ padding: "0px 48px", width: "100%", maxWidth: "1700px" }}
            >
              <div style={{ height: "24px" }}></div>
              <div className="new-table-container-header">
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "32px" }}
                >
                  <TableHeaderLeftValue
                    label={"Total deployed:"}
                    data={data?.totalOutstanding}
                  />
                  <TableHeaderLeftValue
                    label={"Total interest income:"}
                    data={data?.totalInterestReceived}
                  />
                </div>
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "32px" }}
                >
                  <TableHeaderRightValue
                    label={"Show charts"}
                    imguri={chartIcon}
                    onClick={() => setOpenChart(true)}
                  />
                  <TableHeaderRightValue
                    label={"Export to Excel"}
                    imguri={excalIcon}
                    onClick={() => exportData()}
                  />
                </div>
              </div>
              <div style={{ height: "0.5rem" }}></div>
              <Tables
                currencyCode={currencyCode}
                // GetTheCount={GetTheCount}
                data={data?.items}
                setPageSize={setPageSize}
                pageSize={pageSize}
                totalItems={totalItems}
                setPage={setPage}
                page={page}
                loader={loader}
                GetMyPortfolio={GetMyPortfolio}
              />
            </div>
          </div>
        </div>
        <ChartComponent
          setOpen={setOpenChart}
          open={openChart}
          data={data}
          chartData={chartData}
          referenceData={referenceData}
          investedFunds={accountOverviewData?.principalInvested}
        />
        <MyPortfolioFilter
          filters={filters}
          setFilters={setFilters}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          GetMyPortfolio={GetMyPortfolio}
        />
      </>
    </div>
  );
}
