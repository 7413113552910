import React, { useEffect, useState } from "react";
import "./style.scss";
import { Pagination, Select, Table } from "antd";
import {
  StablecoinWarperFunction,
  newNumberformat,
} from "../../../Helper/HelperFunction";
import { PageSizeList1 } from "../../../Helper_Data/SelectBoxData";
import { MdArrowDropDown } from "react-icons/md";
import { Link } from "react-router-dom";
import SortArrows from "../../../UI-Library/SortArrows/SortArrows";
import { DateParser } from "../../../Utils/common";

export default function TransactionsTable({
  data,
  setPageSize,
  pageSize,
  totalItems,
  setPage,
  page,
  loader,
}) {
  const AmountConverter = ({ value }) => {
    const [data, setData] = useState(null);

    useEffect(() => {
      StablecoinWarperFunction(value).then(setData);
    }, [value]);

    if (data === null) return null; // or return a loading spinner

    return <>{data}</>;
  };
  const shortenAddress = (address) => {
    return `${address?.substring(0, 4)}...${address?.substring(
      address?.length - 4
    )}`;
  };
  const columns = [
    {
      title: <span className="non-sorter-columns">Transaction ID</span>,
      dataIndex: "txId",
      key: "txId",
      align: "center",
      width: 155.71,
    },
    {
      title: <span>Date</span>,
      dataIndex: "txTsFormatted",
      key: "txTsFormatted",
      align: "center",
      width: 155.71,
      sorter: (a, b) =>
        DateParser(a.txTsFormatted) - DateParser(b.txTsFormatted),
      sortIcon: ({ sortOrder }) => <SortArrows sortOrder={sortOrder} />,
    },
    {
      title: <span className="non-sorter-columns">Transaction type</span>,
      dataIndex: "txType",
      key: "txType",
      align: "center",
      width: 155.71,
      render: (text, record) => <div>{record.txTypeLabel}</div>,
    },
    {
      title: <span className="non-sorter-columns">Lender</span>,
      dataIndex: "originatorCompanyName",
      key: "originatorCompanyName",
      align: "center",
      width: 155.71,
      render: (text, record) => <>{record.originatorCompanyName || "-"}</>,
    },
    {
      title: <span className="non-sorter-columns">Loan ID</span>,
      dataIndex: "loanId",
      key: "loanId",
      align: "center",
      width: 155.71,
      render: (text, record) => (
        <div>
          {record.loanId ? (
            <div className="" style={{ fontSize: "14px" }}>
              <Link
                className="primary-color"
                style={{ textDecoration: "none" }}
                to={`/user/loan/${record.loanId}`}
              >
                <span style={{ borderBottom: "1px solid #EC441F33" }}>
                  {record.loanId}
                </span>
              </Link>{" "}
            </div>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      title: <span>Transaction amount</span>,
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: 155.71,
      sorter: (a, b) => a.amount - b.amount,
      sortIcon: ({ sortOrder }) => <SortArrows sortOrder={sortOrder} />,
      render: (text, record) => <div>{record.amount || 0}</div>,
    },
    {
      title: <span className="non-sorter-columns">Wallet</span>,
      dataIndex: "walletAddress",
      key: "walletAddress",
      align: "center",
      width: 155.71,
      render: (text, record) => (
        <div>
          {record?.walletAddress ? shortenAddress(record?.walletAddress) : "NA"}
        </div>
      ),
    },
    {
      title: <span>Available balance</span>,
      dataIndex: "cashBalance",
      key: "cashBalance",
      align: "center",
      width: 155.71,
      sorter: (a, b) => a.cashBalance - b.cashBalance,
      sortIcon: ({ sortOrder }) => <SortArrows sortOrder={sortOrder} />,
      render: (text, record) => (
        <div>{newNumberformat(record.cashBalance || 0)}</div>
      ),
    },
  ];
  const handlePageChange = (pageNumber) => {
    console.log(pageNumber);
    setPage(pageNumber);
  };

  const handlePageSizeChange = (newSize) => {
    console.log(newSize);
    // setPageSize(newSize?.value);
    setPageSize(newSize);
  };

  return (
    <div
      className=""
      style={{
        display: "flex",
        background: "#FFFFFF",
        justifyContent: "center",
      }}
    >
      <div className="page-width-control">
        <Table
          columns={columns}
          dataSource={data}
          loading={loader}
          responsive
          scroll={{ x: "max-content" }}
          pagination={false}
          showSizeChanger
          className="custom-table custom-padding-bottom-24"
          showSorterTooltip={{
            title: null,
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: "24px",
            paddingBottom: "24px",
          }}
        >
          <div>
            <Pagination
              current={page}
              pageSize={pageSize}
              total={totalItems}
              onChange={handlePageChange}
              onShowSizeChange={handlePageSizeChange}
              className="custom-pagination"
              pageSizeOptions={["10", "20", "50", "100"]}
              showSizeChanger={false}
            />
          </div>
          <div className="new_input_normal_border_pagination ">
            <Select
              placeholder={"All"}
              className="new_input_normal_border_pagination"
              options={PageSizeList1}
              style={{ width: "100%", height: "40px", paddingRight: "8px" }}
              dropdownStyle={{ zIndex: 1200 }}
              suffixIcon={<MdArrowDropDown color="black" size={24} />}
              // size={"large"}
              value={pageSize}
              onChange={handlePageSizeChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
