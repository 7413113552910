import { Button, Col, Form, Input, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import "./style.css";
import cookie from "react-cookies";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { getWebDeviceData } from "../../Utils/common";
import { clientApi } from "../../Utils/AxiosCall";
import { conversionRate, validateEmail } from "../../Helper/HelperFunction";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useDispatch } from "react-redux";
import {
  LoginToken,
  setTwoFactorAuthenticationStatus,
} from "../../Store/reducers/global";
import SignUpInputBox from "../../Components/Components/Atom/SignUpInputBox/SignUpInputBox";
import PasswordInput from "../../Components/Components/Atom/PasswordInput/PasswordInput";

const InvestorLoginPage = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [errEmail, setErrEmail] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [enable2FA, setEnable2FA] = useState(false);
  const [error, setError] = useState({
    error: false,
    message: "",
  });
  const [validationError, setValidationError] = useState({
    email: {
      error: false,
      message: "",
      exists: false,
    },
    password: {
      error: false,
      message: "",
      exists: false,
    },
    termsAndConditions: {
      error: false,
      message: "",
      exists: false,
    },
  });
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormValues({ ...formData, [name]: value });
  // };

  const validateForm = () => {
    let isValid = true;
    let validationErrorData = {
      email: {
        error: false,
        message: "",
        exists: false,
      },
      password: {
        error: false,
        message: "",
        exists: false,
      },
      termsAndConditions: {
        error: false,
        message: "",
        exists: false,
      },
    };

    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Regular expression for password validation (at least one number)
    const passwordRegex = /.*[0-9].*/;

    if (!formData.email) {
      validationErrorData.email.error = true;
      validationErrorData.email.message = "Email is required";
      validationErrorData.email.exists = false;
      isValid = false;
    } else if (!emailRegex.test(formData.email)) {
      validationErrorData.email.error = true;
      validationErrorData.email.message = "Invalid email format";
      validationErrorData.email.exists = false;
      isValid = false;
    }

    if (!formData.password) {
      validationErrorData.password.error = true;
      validationErrorData.password.message = "Password is required";
      validationErrorData.password.exists = false;
      isValid = false;
    } else if (
      formData.password.length < 6 ||
      !passwordRegex.test(formData.password)
    ) {
      validationErrorData.password.error = true;
      validationErrorData.password.message =
        "Minimal password requirements: 6 symbols and at least one number";
      validationErrorData.password.exists = false;
      isValid = false;
    }

    const hasError = Object.values(validationErrorData).some(
      (field) => field.error
    );

    // Update the error state based on validation results
    if (hasError) {
      setError({
        error: true,
        message: "Form cannot be submitted, see errors below",
      });
    } else {
      setError({
        error: false,
        message: "",
      });
    }
    setValidationError(validationErrorData);
    return isValid;
  };

  const onFinish = async (e) => {
    e.preventDefault();
    console.log(formData, "formData");
    const isValid = validateForm();
    if (!isValid) return;

    setLoader(true);

    try {
      const response = await clientApi.post("/api/investor/public/login", {
        email: formData.email,
        password: formData.password,
        webDeviceData: getWebDeviceData(),
      });
      localStorage.setItem("investorId", response.data.investorNumber);
      localStorage.setItem("investorFirstName", response.data.firstName);
      localStorage.setItem(
        "2FAStatus",
        response.data.twoFactorAuthenticationEnabled
      );
      localStorage.setItem("investorStatus", response.data.investorStatus);
      localStorage.setItem("investorEmail", response.data.email);
      const status = response.data.investorStatus;
      if (response.status === 200) {
        setToLocalStorage(response, status);
        dispatch(
          setTwoFactorAuthenticationStatus(
            response.data.twoFactorAuthenticationEnabled
          )
        );
      } else {
        setLoader(false);
        const errMsg = "login failed";
        message.error(errMsg);
      }

      // if (response.data.twoFactorAuthenticationEnabled === true) {
      //   setEnable2FA(true);
      // } else {

      // }
    } catch (error) {
      let errMsg;
      if (error?.response?.data?.validation?.errors[0] === "INVALID_LOGIN")
        setValidationError({
          ...validationError,
          email: { error: true, message: "", exists: true },
          password: { error: true, message: "", exists: true },
        });
      errMsg = "Email or password is wrong. Please try again!";
      message.error(errMsg || error?.response?.data?.message);
      setLoader(false);
    }
  };

  const onEnable2FA = async (values) => {
    if (!values?.totp) {
      // return setErr({ status: true, message: "Please enter the code!" });
    }
    if (values.totp?.length !== 6) {
      // return setErr({ status: true, message: "Invalid code!" });
    }
    setLoader(true);
    try {
      const response = await clientApi.post(
        "/api/investor/public/confirm-login",
        { totp: values.totp }
      );
      setToLocalStorage(response);
    } catch (error) {
      if (
        error?.response?.data?.validation?.fieldErrors?.totp === "INVALID_VALUE"
      ) {
        // setErr({ status: true, message: "Invalid code!" });
        setLoader(false);
      }
    }
  };
  const setToLocalStorage = async (response, status) => {
    console.log(response, "response");
    await conversionRate();
    if (status === "REGISTRATION" && response?.data?.emailVerified === false) {
      navigate("/user/verify-email");
    }
    if (status === "REGISTRATION") {
      navigate("/user/personal-data");
    } else if (status === "NOT_VERIFIED") {
      setLoader(false);
      navigate("/user/veriff-landing");
    } else {
      const token = "token" + Math.floor(Math.random() * 1000000);
      localStorage.setItem("token", token);
      cookie.save("token", token);
      console.log(token);
      dispatch(LoginToken(token)); // dispatch the action to store the token in Redux
      setLoader(false);
      navigate("/user/overview");
      // navigate("/investor/veriff-landing");
    }
  };

  return (
    <div className="onboarding-page-container">
      <Row justify="center" align="middle" className="antd-row-height-style">
        <Col xs={24} sm={16} md={16} lg={10}>
          <>
            <center
              className="login-sign-up-heading"
              style={{ paddingTop: "3rem" }}
            >
              Sign In
            </center>
            <form
              onSubmit={onFinish}
              className="onboarding-page-form"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div>
                <SignUpInputBox
                  label={"Email address"}
                  placeholder={"Your email address"}
                  isRequired={true}
                  value={formData.email}
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value });
                  }}
                  validationError={validationError.email}
                />
              </div>
              <div className="gap-between-inputs">
                <PasswordInput
                  label={"Password"}
                  placeholder={"Enter your password"}
                  isRequired={true}
                  value={formData.password}
                  onChange={(e) => {
                    setFormData({ ...formData, password: e.target.value });
                  }}
                  validationError={validationError.password}
                />
              </div>
              <div style={{ marginTop: "8px" }}>
                <Link
                  to={"/user/forgot-password"}
                  style={{
                    color: "black",
                    fontSize: "14px",
                    textDecoration: "none",
                  }}
                >
                  Forgot password?
                </Link>
              </div>
              <div className="form-item" style={{ marginTop: "32px" }}>
                <Button
                  className={`${
                    !formData.email || !formData.password
                      ? "new_button_filled_disabled"
                      : "new_button_filled"
                  }   hight-56`}
                  style={{ width: "100%" }}
                  htmlType="submit"
                  loading={loader}
                  disabled={!formData.email || !formData.password}
                >
                  Sign in
                </Button>
              </div>
            </form>
            <div style={{ marginTop: "3rem" }}>
              <div style={{ textAlign: "center" }}>
                New to Fu Capital?
                <Link
                  style={{
                    marginLeft: 8,
                    color: "#EC441F",
                    textDecoration: "none",
                  }}
                  // onClick={() => window.location.assign("/user/signUp")}
                  to={"/user/signUp"}
                >
                  Create profile
                </Link>
              </div>
            </div>
          </>
        </Col>
      </Row>
    </div>
  );
};

export default InvestorLoginPage;
