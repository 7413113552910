// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-table-column-sorters {
  display: flex;
  flex-direction: column-reverse;
  gap: 0.25rem;
}

.non-sorter-columns {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/PortfolioOptimized/Table/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;AACF","sourcesContent":[".ant-table-column-sorters {\n  display: flex;\n  flex-direction: column-reverse;\n  gap: 0.25rem;\n}\n\n.non-sorter-columns {\n  display: flex;\n  flex-direction: column;\n  padding-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
