import React from "react";
import "./style.scss";
import { newNumberformat } from "../../../Helper/HelperFunction";
import excelIcon from "../../../Assets/images/export-excel.svg";

export default function StatementSummary({ data, exportData }) {
  const BoldDataComponent = ({ label, value }) => {
    return (
      <>
        <div className="summery-container-body-data summery-container-body-data-bold">
          <div>{label}</div>
          <div>{newNumberformat(value) || 0}</div>
        </div>
      </>
    );
  };
  const NormalDataComponent = ({ label, value }) => {
    return (
      <>
        <div className="summery-container-body-data ">
          <div className="summery-container-body-data-label">{label}</div>
          <div className="summery-container-body-data-label-bold">
            {newNumberformat(value) || 0}
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="summery-container">
      <div className="heading">Summary</div>
      <div className="summery-container-body">
        <BoldDataComponent
          label="Opening Balance"
          value={data?.openingBalance}
        />
        <NormalDataComponent label="Added funds" value={data?.deposit} />
        <NormalDataComponent label="Withdrawal" value={data?.withdrawal} />
        <NormalDataComponent
          label="Deployed on the Primary market"
          value={data?.primaryMarketPrincipalInvested}
        />
        <NormalDataComponent
          label="Deployed on the Secondary market"
          value={data?.secondaryMarketPrincipalBought}
        />
        <NormalDataComponent
          label="Sold on Secondary Market"
          value={data?.secondaryMarketPrincipalSold}
        />
        <NormalDataComponent
          label="Principal income"
          value={data?.principalReceived}
        />
        <NormalDataComponent
          label="Interest income"
          value={data?.interestReceived}
        />
        <NormalDataComponent
          label="Bonus Received"
          value={data?.bonusReceived}
        />
        <NormalDataComponent
          label="Referral bonus received"
          value={data?.referralBonusReceived}
        />
        <NormalDataComponent
          label="Secondary Market Income"
          value={data?.secondaryMarketIncome}
        />
        {/* <NormalDataComponent label="Secondary Market Expense" value={data?.secondaryMarketExpense} /> */}
        <BoldDataComponent
          label="Closing Balance"
          value={data?.closingBalance}
        />
      </div>
      <div className="export-pdf">
        <div className="export-pdf-div" onClick={() => exportData("pdf")}>
          <div>
            <img src={excelIcon} alt="pdf" />
          </div>
          <div>Download PDF statement</div>
        </div>
      </div>
    </div>
  );
}
