import React from "react";
import "./style.scss";
import PdfIcon from "../../../Assets/images/pdf-icon.svg";

export default function StatementExportToExcel({ count, exportData }) {
  console.log(count);
  return (
    <div className="custom-padding-top-48">
      <div
        className=""
        style={{
          display: "flex",
          backgroundColor: "#FFFFFFA6",
          justifyContent: "center",
        }}
      >
        <div className="page-width-control">
          <div
            className="d-flex"
            style={{
              justifyContent: "space-between",
              height: "72px",
              borderBottom: "1px solid #2424240A",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <div className="transactions-text">Total Transactions:</div>
              <div className="transactions-value">{count || 0}</div>
            </div>
            <div
              className="export-pdf"
              style={{ display: "flex", alignItems: "center", height: "72px" }}
            >
              <div
                className="export-excl-div"
                onClick={() => exportData("excel")}
              >
                <div>
                  <img src={PdfIcon} alt="pdf" />
                </div>
                <div>Export to excel</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
