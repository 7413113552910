import React from "react";
import "./style.scss";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

function SortArrows({ sortOrder }) {
  switch (sortOrder) {
    case "ascend":
      return <ArrowUpOutlined />;
    case "descend":
      return <ArrowDownOutlined />;
    default:
      return <div className="arrow-div"></div>;
  }
}

export default SortArrows;
