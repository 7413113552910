import React, { useEffect } from "react";
import "./style.css";
import { clientApi, GetProfile } from "../../Utils/AxiosCall";
import { useNavigate } from "react-router-dom";

const UnderMaintenance = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await clientApi.get("/api/investor/profile");
        console.log(data, "data");
        if (data && data?.data?.firstName) {
          navigate("/");
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };
    //immediate call
    fetchProfile();
    // Call Profile API every 10 seconds to see if the backend is active or not
    const intervalId = setInterval(() => fetchProfile(), 10000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div id="main">
      <div className="fof">
        <h1>We're sorry, something went wrong</h1>
        <p>
          We apologize for the inconvenience, please wait or try again after
          some time.
        </p>
      </div>
    </div>
  );
};

export default UnderMaintenance;
