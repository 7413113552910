import React from "react";
import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Layout, Menu, theme } from "antd";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import {
  DepositIcon,
  HelpIcon,
  MarketIcon,
  OverviewIcon,
  PortfolioIcon,
  StatementIcon,
} from "../Helper/CustomIcons";
import { TfiHelpAlt } from "react-icons/tfi";
import FuCapitalLogo from "../Assets/images/FUCapitallogo.svg";
import FuCapitalLogoSmall from "../Assets/images/FUsymbol.svg";
import personAdd from "../Assets/images/person_add.svg";
import { gitBookUrl, versionNo } from "../Utils/AxiosCall";
import GuestHeader from "../Components/GuestComponents/Header/GuestHeader";
import "./style.css";
const { Header, Content, Footer, Sider } = Layout;
export default function GuestLayout() {
  const [selectedKey, setSelectedKey] = useState(window.location.pathname);
  const [collapsed, setCollapsed] = useState(false);
  useEffect(() => {
    setSelectedKey(window.location.pathname);
  }, [window.location.pathname]);
  return (
    <div>
      <Layout className="custom-menu">
        <Sider
          className="custom-sider hide-scrollbar"
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          width={250}
          collapsedWidth={64}
          style={{
            background: `#242424`,
            // overflow: "auto",
            height: "100vh",
            position: "sticky",
            left: 0,
            top: 0,
          }}
          trigger={null}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div>
              <div
                style={{
                  height: 56,
                  backgroundColor: "#161616B2",
                }}
                className={`${
                  collapsed
                    ? "custom-header-close-side-bar"
                    : "custom-header-open-side-bar"
                }`}
              >
                <Link to={"/user/overview"} style={{ textDecoration: "none" }}>
                  <img
                    src={collapsed ? FuCapitalLogoSmall : FuCapitalLogo}
                    alt="logo"
                    width={collapsed ? 40 : 84.97}
                    height={collapsed ? 12.5 : 32}
                    style={{
                      transition:
                        "transform 0.5s ease, width 0.5s ease, height 0.5s ease",
                    }}
                  />
                </Link>
                {!collapsed && (
                  <div
                    className="collapse-toggle-button-header collapse-toggle-button-header-open"
                    onClick={() => {
                      setCollapsed(!collapsed);
                    }}
                  >
                    <AiOutlineDoubleLeft size={14} color="white" />
                  </div>
                )}
              </div>
              <div className={`custom-menu ${collapsed ? "collapsed" : ""}`}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "32px 0px 12px 0px",
                  }}
                >
                  {/* <Link to="/investor/account-overview" style={{textDecoration: "none"}}> */}
                  <div className={"custom-menu-items"}>
                    <div className={"custom-menu-items-inside-disabled"}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "16px",
                        }}
                      >
                        <div
                          className={
                            collapsed
                              ? "custom-menu-items-inside-icon-padding-collapsed"
                              : "custom-menu-items-inside-icon-padding"
                          }
                        >
                          <OverviewIcon />
                        </div>
                        {!collapsed && (
                          <div
                            className="custom-menu-items-font"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Overview
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                  {/* <Link to="/investor/portfolio" style={{textDecoration: "none"}}> */}
                  <div className={"custom-menu-items"}>
                    <div className={"custom-menu-items-inside-disabled"}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "16px",
                        }}
                      >
                        <div
                          className={
                            collapsed
                              ? "custom-menu-items-inside-icon-padding-collapsed"
                              : "custom-menu-items-inside-icon-padding"
                          }
                        >
                          <PortfolioIcon />
                        </div>
                        {!collapsed && (
                          <div
                            className="custom-menu-items-font"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Portfolio
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                  <Link
                    to="/user/guest-market-page"
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      className={
                        selectedKey === "/user/guest-market-page"
                          ? "custom-menu-items-selected"
                          : "custom-menu-items"
                      }
                    >
                      <div
                        className={
                          selectedKey === "/user/guest-market-page"
                            ? "custom-menu-items-inside-selected"
                            : "custom-menu-items-inside"
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "16px",
                          }}
                        >
                          <div
                            className={
                              collapsed
                                ? "custom-menu-items-inside-icon-padding-collapsed"
                                : "custom-menu-items-inside-icon-padding"
                            }
                          >
                            <MarketIcon />
                          </div>
                          {!collapsed && (
                            <div
                              className="custom-menu-items-font"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              Market
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                  {/* <Link to="/investor/account-statement" style={{textDecoration: "none"}}> */}
                  <div className={"custom-menu-items"}>
                    <div className={"custom-menu-items-inside-disabled"}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "16px",
                        }}
                      >
                        <div
                          className={
                            collapsed
                              ? "custom-menu-items-inside-icon-padding-collapsed"
                              : "custom-menu-items-inside-icon-padding"
                          }
                        >
                          <StatementIcon />
                        </div>
                        {!collapsed && (
                          <div
                            className="custom-menu-items-font"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Statement
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                  {/* <Link to="/investor/deposit-funds" style={{textDecoration: "none"}}> */}
                  <div className={"custom-menu-items"}>
                    <div className={"custom-menu-items-inside-disabled"}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "16px",
                        }}
                      >
                        <div
                          className={
                            collapsed
                              ? "custom-menu-items-inside-icon-padding-collapsed"
                              : "custom-menu-items-inside-icon-padding"
                          }
                        >
                          <DepositIcon />
                        </div>
                        {!collapsed && (
                          <div
                            className="custom-menu-items-font"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Add&nbsp;and&nbsp;Withdraw
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
              </div>
            </div>

            <div style={{ paddingBottom: "12px" }}>
              {!collapsed ? (
                <div
                  className="guest-slider-sign-up-conditioner"
                  style={{
                    transition:
                      "transform 1.5s ease, width 1.5s ease, height 1.5s ease",
                  }}
                >
                  <div className="guest-slider-sign-up-sub-title">
                    This is a demo page
                  </div>
                  <div
                    className="guest-slider-sign-up-mani-title"
                    style={{ paddingBottom: "8px" }}
                  >
                    Create profile to use FU Capital platform
                  </div>
                  <Link to={"/user/signUp"} style={{ textDecoration: "none" }}>
                    <div className="guest-slider-sign-up-button">
                      Sign up
                      <span style={{ paddingLeft: "8px" }}>
                        <img src={personAdd} alt="img" />
                      </span>
                    </div>
                  </Link>
                </div>
              ) : (
                <div
                  className="guest-slider-sign-up-conditioner-collapsed"
                  style={{
                    transition:
                      "transform 1.5s ease, width 1.5s ease, height 1.5s ease",
                  }}
                >
                  <Link to={"/user/signUp"} style={{ textDecoration: "none" }}>
                    <div className="guest-slider-sign-up-button">
                      <span>
                        <img src={personAdd} alt="img" />
                      </span>
                    </div>
                  </Link>
                </div>
              )}

              <div
                style={{ fontSize: "8px", color: "#fff", paddingLeft: "13px" }}
              >
                version:{versionNo}
              </div>
              <a
                target="_blank"
                href={gitBookUrl}
                style={{ textDecoration: "none" }}
              >
                <div className={"custom-menu-items"}>
                  <div className={"custom-menu-items-inside"}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "16px",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className={
                          collapsed
                            ? "custom-menu-items-inside-icon-padding-collapsed"
                            : "custom-menu-items-inside-icon-padding"
                        }
                      >
                        <TfiHelpAlt size={32} />
                      </div>
                      {!collapsed && (
                        <div
                          className="custom-menu-items-font"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          Help
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </Sider>
        <Layout>
          <Header
            className="custom-header"
            style={{
              zIndex: 4,
              background: "transparent",
              position: "sticky",
              top: 0,
            }}
          >
            {/* <ActiveHeader {...walletProps} /> */}
            <GuestHeader collapsed={collapsed} setCollapsed={setCollapsed} />
          </Header>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
            }}
          >
            <div style={{ flex: 1, overflow: "auto" }}>
              <Content
                style={{
                  overflow: "hidden",
                  height: "100%",
                }}
              >
                <div>
                  <Outlet />
                </div>
              </Content>
            </div>
            <div className="custom-footer">
              <Footer className="d-flex justify-content-center">
                <span>©2024, FU Capital Inc.</span>
              </Footer>
            </div>
          </div>
        </Layout>
      </Layout>
    </div>
  );
}
