import { message, Table, Pagination, Input, Button, Select } from "antd";
import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  GetProfile,
  GetWalletBalance,
  HandelError,
  clientApi,
} from "../../Utils/AxiosCall";
import { Link } from "react-router-dom";
import { useAccount } from "wagmi";
import ReactCountryFlag from "react-country-flag";
import { FiFileText } from "react-icons/fi";
import countries from "i18n-iso-countries";
import "./style.scss";
import InvestingOnHold from "../StatusModels/InvestingOnHold";
import MarketSecondaryInvestSuccessModal from "../SuccessModal/MarketSecondaryInvestSuccessModal";
import {
  AmountConverter,
  newNumberformat,
  StablecoinWarperFunction,
} from "../../Helper/HelperFunction";
import { MdArrowDropDown } from "react-icons/md";
import { PageSizeList1, WalletTestData } from "../../Helper_Data/SelectBoxData";
import close_icon from "../../Assets/images/IconButton.svg";
import agreement_icon from "../../Assets/images/description.svg";
import errorIcon from "../../Assets/images/error-icon-market-box.svg";
import { Modal } from "antd";
import checkIcons from "../../Assets/images/check-icon-invest.svg";
import { IoMdArrowDropdown } from "react-icons/io";
import { MetaMaskAvatar } from "react-metamask-avatar";
import add_circle from "../../Assets/images/add-circle-outline.svg";
import { walletLogos } from "../../Helper/CustomIcons";
import { DateParser } from "../../Utils/common";
import SortArrows from "../../UI-Library/SortArrows/SortArrows";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
const { Option } = Select;
const SecondaryMarketTable = forwardRef(
  (
    {
      isBuy,
      filters,
      availableLoan,
      setAvailableLoan,
      isOpened,
      setIsOpened,
      setTotalCount,
    },
    ref
  ) => {
    const currencyCode = useSelector(
      (state) => state.global.SelectedStableCoin
    );
    const dispatch = useDispatch();
    const [apiLoad, setApiLoad] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [veriffStatus, setVeriffStatus] = useState("false");
    const [profileData, setProfileData] = useState("");
    const [holdInvesting, setHoldInvesting] = useState(false);
    const [InvestmentSucessfulModalStatus, setInvestmentSucessfulModalStatus] =
      useState(false);
    const [InvestmentSucessfulModalData, setInvestmentSucessfulData] =
      useState("");
    const columns1 = [
      {
        title: <div className="non-sorter-columns">Country</div>,
        dataIndex: "countryCode",
        key: "countryCode",
        align: "center",
        width: 110.4,
        render: (ele, key) => {
          const countryName = countries.getName(ele, "en", {
            select: "official",
          });
          return (
            <div>
              <ReactCountryFlag countryCode={ele} svg />
              <p>{countryName}</p>
            </div>
          );
        },
      },
      {
        title: <div className="non-sorter-columns">Loan id</div>,
        dataIndex: "loanId",
        align: "center",
        width: 110.4,
        render: (text, record) => (
          <div>
            <div className="" style={{ fontSize: "14px" }}>
              <Link
                className="primary-color"
                style={{ textDecoration: "none" }}
                to={`/user/loan/${record.loanId}`}
              >
                <span style={{ borderBottom: "1px solid #EC441F33" }}>
                  {record.loanId}
                </span>
              </Link>{" "}
            </div>
            <div className="" style={{ fontSize: "12px" }}>
              {record.productLabel}
            </div>
          </div>
        ),
      },
      {
        title: <div className="non-sorter-columns">Lender</div>,
        dataIndex: "originatorCompanyName",
        align: "center",
        width: 110.4,
      },

      {
        title: <span className="table-title">Listed on the platform</span>,
        dataIndex: "issueDate",
        align: "center",
        width: 110.4,
        sorter: (a, b) => DateParser(a.issueDate) - DateParser(b.issueDate),
        sortIcon: ({ sortOrder }) => <SortArrows sortOrder={sortOrder} />,
      },
      {
        title: "Interest ",
        dataIndex: "interestRatePercent",
        align: "center",
        width: 110.4,
        sorter: (a, b) => a.interestRatePercent - b.interestRatePercent,
        sortIcon: ({ sortOrder }) => <SortArrows sortOrder={sortOrder} />,
        render: (text, record) => (
          <div>
            <div>{newNumberformat(record.interestRatePercent)}%</div>
          </div>
        ),
      },
      {
        title: "Remaining duration",
        dataIndex: "termInDays",
        align: "center",
        width: 110.4,
        sorter: (a, b) => a.termInDays - b.termInDays,
        sortIcon: ({ sortOrder }) => <SortArrows sortOrder={sortOrder} />,
        render: (text, record) => (
          <span>
            {record.termInDays >= 0 ? (
              <>
                <span>
                  {record.termPeriod ? (
                    <>
                      {record.termPeriod.years ? (
                        <>{record.termPeriod.years}y.&nbsp;</>
                      ) : (
                        <></>
                      )}
                      {record.termPeriod.months ? (
                        <>{record.termPeriod.months}m.&nbsp;</>
                      ) : (
                        <></>
                      )}
                      {record.termPeriod.days ? (
                        <>{record.termPeriod.days}d.</>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </>
            ) : record.termInDays < 0 ? (
              <>
                <span class="text-danger-1">{record.termInDays}d</span>
              </>
            ) : (
              ""
            )}

            {/* <div className='table-column-color' >{record.collectionStatus}</div> */}
          </span>
        ),
      },
      {
        title: "Loan maturity date",
        dataIndex: "maturityDate",
        key: "maturityDate",
        align: "center",
        width: 106.9,
        sorter: (a, b) =>
          DateParser(a.maturityDate) - DateParser(b.maturityDate),
        sortIcon: ({ sortOrder }) => <SortArrows sortOrder={sortOrder} />,
      },
      {
        title: <span className="table-title">Available loan amount</span>,
        dataIndex: "smOfferPrincipalAvailable",
        align: "center",
        width: 110.4,
        sorter: (a, b) =>
          a.smOfferPrincipalAvailable - b.smOfferPrincipalAvailable,
        sortIcon: ({ sortOrder }) => <SortArrows sortOrder={sortOrder} />,
        render: (text, record) => (
          <AmountConverter value={record.smOfferPrincipalAvailable} />
        ),
      },
      {
        title: <>Price&nbsp;and&nbsp;discount/ premium</>,
        dataIndex: "smPrice",
        align: "center",
        width: 110.4,
        sorter: (a, b) => a.smPrice - b.smPrice,
        sortIcon: ({ sortOrder }) => <SortArrows sortOrder={sortOrder} />,
        render: (text, record) => (
          <div>
            {record.smPrice ? (
              <>
                <AmountConverter value={record.smPrice} />
                <div className="discount-labels-font">
                  {record.smDiscountOrPremiumPercent > 0 ? (
                    <>
                      <span style={{ color: "var(--error-color)" }}>
                        {record.smDiscountOrPremiumPercent
                          ? `${newNumberformat(
                              record.smDiscountOrPremiumPercent
                            )}%`
                          : ""}
                      </span>
                    </>
                  ) : record.smDiscountOrPremiumPercent < 0 ? (
                    <>
                      <span style={{ color: "var(--success-color)" }}>
                        {record.smDiscountOrPremiumPercent
                          ? `${newNumberformat(
                              record.smDiscountOrPremiumPercent
                            )}%`
                          : ""}
                      </span>
                    </>
                  ) : (
                    <span className="discount-labels-font-zero">0,0%</span>
                  )}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        ),
      },

      {
        title: "",
        dataIndex: "Action",
        key: "Action",
        align: "center",
        width: 68,
        render: (ele, key) => (
          <Button
            className="new_button_filled-font-12px"
            onClick={() => {
              console.log({ key });
              // setCurrentObj(key);
              // dispatch(primaryInvestBasket(key));
              // investButton();
              handleInvest(
                key.loanId,
                key.investmentId,
                key.smOfferPrincipalAvailable,
                key.interestRatePercent,
                key.smDiscountOrPremiumPercent
              );
            }}
            style={{ height: 32, width: 60 }}
          >
            Buy
          </Button>
        ),
      },
    ];

    let filter = {};
    const checkFilter = async () => {
      if (filters.buybackOnly) filter.buybackOnly = filters.buybackOnly;
      if (filters.collectionStatuses?.length > 0)
        filter.collectionStatuses = filters.collectionStatuses;
      if (filters.countries?.length > 0) filter.countries = filters.countries;
      filter.currencyCode = currencyCode;
      if (filters.excludeAlreadyInvested)
        filter.excludeAlreadyInvested = filters.excludeAlreadyInvested;
      if (filters.extensions === true || filters.extensions === false)
        filter.extensions = filters.extensions;
      if (
        filters.interestRatePercentFrom !== null &&
        filters.interestRatePercentFrom !== ""
      )
        filter.interestRatePercentFrom = filters.interestRatePercentFrom;
      if (
        filters.interestRatePercentTo !== null &&
        filters.interestRatePercentTo !== ""
      )
        filter.interestRatePercentTo = filters.interestRatePercentTo;
      if (filters.loanIds?.length > 0) filter.loanIds = filters.loanIds;
      if (filters.originators?.length > 0)
        filter.originators = filters.originators;
      if (
        filters.principalOfferFrom !== null &&
        filters.principalOfferFrom !== ""
      )
        filter.principalOfferFrom = filters.principalOfferFrom;
      if (filters.principalOfferTo !== null && filters.principalOfferTo !== "")
        filter.principalOfferTo = filters.principalOfferTo;
      if (filters.products.length > 0) filter.products = filters.products;
      if (
        filters.remainingTermInDaysFrom !== null &&
        filters.remainingTermInDaysFrom !== ""
      )
        filter.remainingTermInDaysFrom = filters.remainingTermInDaysFrom;
      if (
        filters.remainingTermInDaysTo !== null &&
        filters.remainingTermInDaysTo !== ""
      )
        filter.remainingTermInDaysTo = filters.remainingTermInDaysTo;
      if (
        filters.smOfferPrincipalAvailableFrom !== null &&
        filters.smOfferPrincipalAvailableFrom !== ""
      )
        filter.smOfferPrincipalAvailableFrom =
          filters.smOfferPrincipalAvailableFrom;
      if (
        filters.smOfferPrincipalAvailableTo !== null &&
        filters.smOfferPrincipalAvailableTo !== ""
      )
        filter.smOfferPrincipalAvailableTo =
          filters.smOfferPrincipalAvailableTo;
      if (filters.smPriceFrom !== null && filters.smPriceFrom !== "")
        filter.smPriceFrom = filters.smPriceFrom;
      if (filters.smPriceTo !== null && filters.smPriceTo !== "")
        filter.smPriceTo = filters.smPriceTo;
      if (
        filters.smDiscountOrPremiumPercentFrom !== null &&
        filters.smDiscountOrPremiumPercentFrom !== ""
      )
        filter.smDiscountOrPremiumPercentFrom =
          filters.smDiscountOrPremiumPercentFrom;
      if (
        filters.smDiscountOrPremiumPercentTo !== null &&
        filters.smDiscountOrPremiumPercentTo !== ""
      )
        filter.smDiscountOrPremiumPercentTo =
          filters.smDiscountOrPremiumPercentTo;
      if (filters.loanDurationInDaysFrom !== null)
        filter.loanDurationInDaysFrom = filters.loanDurationInDaysFrom;
      if (filters.loanDurationInDaysTo !== null)
        filter.loanDurationInDaysTo = filters.loanDurationInDaysTo;

      return filter;
    };

    const getData = async () => {
      setAvailableLoan(0);
      setApiLoad(true);
      await checkFilter();
      console.log("secondary", filter);
      clientApi
        .post("/api/investor/public/query-secondary-market", {
          filter: filter,
          page,
          pageSize,
        })
        .then((res) => {
          console.log(res);
          setTotalCount(res?.data);
          setTableData(res.data?.items);
          setTotalItems(res.data?.total);
          setAvailableLoan(res.data?.total);
          setApiLoad(false);
        })
        .catch((err) => {
          setApiLoad(false);
          console.log(err);
        });
    };
    useImperativeHandle(ref, () => ({
      getData,
    }));

    useEffect(() => {
      getData();
      GetProfileDetailes();
      const GetData = async () => {
        const response = await localStorage.getItem("investorStatus");
        setVeriffStatus(response);
      };
      GetData();
    }, [currencyCode]);

    const GetProfileDetailes = async () => {
      const response = await GetProfile();
      setProfileData(response);
      if (response?.holdInvesting) {
        return setHoldInvesting(true);
      }
    };
    const handlePageChange = (pageNumber) => {
      console.log(pageNumber);
      setPage(pageNumber);
    };

    const handlePageSizeChange = (newSize) => {
      console.log(newSize);
      setPageSize(newSize);
    };
    useEffect(() => {
      getData();
    }, [page, pageSize]);
    const [formState, setFormState] = useState({
      loanId: "",
      investmentId: "",
      amountToBuy: "0",
      cashAvailable: "0.0",
      principalAvailable: "",
      currencyCode: "",
      currencySymbol: "",
      interestRatePercent: "",
      smDiscountOrPremiumPercent: "",
    });
    const [isOpenModalBuySelected, setIsOpenModalBuySelected] = useState(false);
    const handleInvest = async (
      loanId,
      investmentId,
      smOfferPrincipalAvailable,
      interestRatePercent,
      smDiscountOrPremiumPercent
    ) => {
      if (veriffStatus === "NOT_VERIFIED") {
        return setIsOpened(true);
      }
      GetProfileDetailes();
      if (!profileData?.holdInvesting) {
        try {
          const response = await clientApi.post(
            `/api/investor/buy-investment-options`,
            { investmentId: investmentId }
          );
          if (response?.status === 200) {
            setFormState({
              loanId: loanId,
              investmentId: investmentId,
              amountToBuy: response.data.principalAvailable || "0",
              cashAvailable: response.data.cashAvailable,
              principalAvailable: response.data.principalAvailable,
              currencyCode: response.data.currencyCode,
              currencySymbol: response.data.currencySymbol,
              interestRatePercent: interestRatePercent,
              smDiscountOrPremiumPercent: smDiscountOrPremiumPercent,
            });
          }
          setIsOpenModalBuySelected(true);
          console.log(response);
        } catch (error) {
          HandelError(error);
        }
      }
    };

    useEffect(() => {
      console.log(formState);
    }, [formState]);

    return (
      <div>
        <div className="">
          <Table
            columns={columns1}
            dataSource={tableData}
            loading={apiLoad}
            responsive
            scroll={{ x: "max-content" }}
            pagination={false}
            showSizeChanger
            className="custom-table custom-padding-bottom-24"
            showSorterTooltip={{
              title: null,
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: "24px",
              paddingBottom: "24px",
            }}
          >
            <div>
              <Pagination
                current={page}
                pageSize={pageSize}
                total={totalItems}
                onChange={handlePageChange}
                onShowSizeChange={handlePageSizeChange}
                className="custom-pagination"
                pageSizeOptions={["10", "20", "50", "100"]}
                showSizeChanger={false}
              />
            </div>
            <div className="new_input_normal_border_pagination ">
              <Select
                placeholder={"All"}
                className="new_input_normal_border_pagination"
                options={PageSizeList1}
                style={{ width: "100%", height: "40px", paddingRight: "8px" }}
                dropdownStyle={{ zIndex: 1200 }}
                suffixIcon={<MdArrowDropDown color="black" size={24} />}
                // size={"large"}
                value={pageSize}
                onChange={handlePageSizeChange}
              />
            </div>
          </div>
        </div>

        <InvestModalBuySelected
          isOpenModalBuySelected={isOpenModalBuySelected}
          setIsOpenModalBuySelected={setIsOpenModalBuySelected}
          formState={formState}
          setFormState={setFormState}
          getData={getData}
          InvestmentSucessfulModalStatus={InvestmentSucessfulModalStatus}
          setInvestmentSucessfulModalStatus={setInvestmentSucessfulModalStatus}
          setInvestmentSucessfulData={setInvestmentSucessfulData}
        />
        <InvestingOnHold isOpen={holdInvesting} setIsOpen={setHoldInvesting} />
        <MarketSecondaryInvestSuccessModal
          status={InvestmentSucessfulModalStatus}
          setStatus={setInvestmentSucessfulModalStatus}
          data={InvestmentSucessfulModalData}
          setData={setInvestmentSucessfulData}
        />
      </div>
    );
  }
);

const InvestModalBuySelected = ({
  isOpenModalBuySelected,
  setIsOpenModalBuySelected,
  formState,
  setFormState,
  getData,
  setInvestmentSucessfulModalStatus,
  setInvestmentSucessfulData,
}) => {
  const [error, setError] = useState("");
  const [SelectedWalletAddress, setSelectedWalletAddress] = useState("");
  const [SelectedWalletDetails, setSelectedWalletDetails] = useState({});
  const [walletListDetails, setWalletListDetails] = useState({});
  const SelectedStableCoin = useSelector(
    (state) => state?.global?.SelectedStableCoin
  );
  const handleClose = () => {
    setIsOpenModalBuySelected(false);
    setFormState({
      loanId: "",
      investmentId: "",
      amountToBuy: "0",
      cashAvailable: "0.0",
      principalAvailable: "",
      currencyCode: "",
      currencySymbol: "",
      interestRatePercent: "",
      smDiscountOrPremiumPercent: "",
    });
  };
  const Buy = async () => {
    setError("");
    //     console.log(SelectedWalletAddress)
    // return console.log(formState);
    try {
      const response = await clientApi.post(`/api/investor/buy-investment`, {
        investmentId: formState?.investmentId,
        amount: formState?.amountToBuy,
        walletAddress: SelectedWalletAddress,
      });
      if (response?.status === 200) {
        setIsOpenModalBuySelected(false);
        setInvestmentSucessfulData(formState?.loanId);
        setInvestmentSucessfulModalStatus(true);
        setError("");
        await getData();
      }
    } catch (error) {
      if (
        error?.response?.data?.validation?.fieldErrors?.amount ===
        "INVALID_VALUE"
      ) {
        message.error("Invalid Amount");
        // setError("Invalid Amount");
      } else if (error?.response?.data?.validation?.errors[0]) {
        message.error(error?.response?.data?.validation?.errors[0]);
        // setError(error?.response?.data?.validation?.errors[0]);
      } else {
        message.error("Investment Failed");
      }
      // HandelError(error);
    }
  };
  useEffect(() => {
    setError("");
  }, [isOpenModalBuySelected]);
  const [balance, setBalance] = useState("0");
  useEffect(() => {
    console.log(formState);
    const fetchBalance = async () => {
      if (formState.cashAvailable) {
        const amount = formState.cashAvailable;
        const amounts = await StablecoinWarperFunction(amount);
        setBalance(amounts);
      }
    };

    fetchBalance();
  }, [formState]);
  const [insufficientBalance, setInsufficientBalance] = useState(false);
  useEffect(() => {
    console.log(
      SelectedWalletDetails?.walletBalance < formState?.principalAvailable
    );
    console.log(SelectedWalletDetails?.walletBalance);
    console.log(formState?.principalAvailable);
    if (SelectedWalletDetails?.walletBalance < formState?.principalAvailable) {
      setInsufficientBalance(true);
    } else {
      setInsufficientBalance(false);
    }
  }, [formState, SelectedWalletDetails]);
  const shortenAddress = (address) => {
    return `${address?.substring(0, 8)}...${address?.substring(
      address?.length - 8
    )}`;
  };
  const handleChange = (value) => {
    const addressToUse = value;
    setSelectedWalletAddress(addressToUse);
    const selectedItem = walletListDetails?.walletBalances?.find(
      (item) => item.walletAddress === addressToUse
    );
    setSelectedWalletDetails(selectedItem);
  };

  const navbarWalletAddress = useSelector(
    (state) => state.global.SelectedWalletAddress
  );

  useEffect(() => {
    console.log("walletListDetails:", walletListDetails);
    console.log("navbarWalletAddress:", navbarWalletAddress);
    console.log("SelectedWalletAddress:", SelectedWalletAddress);

    if (navbarWalletAddress && walletListDetails) {
      const selectedWallet = walletListDetails?.walletBalances?.find(
        (wallet) => wallet.walletAddress === navbarWalletAddress
      );
      console.log("selectedWallet:", selectedWallet);
      if (selectedWallet) {
        console.log(
          "Setting selected wallet address to navbarWalletAddress:",
          navbarWalletAddress
        );
        setSelectedWalletAddress(navbarWalletAddress);
        setSelectedWalletDetails(selectedWallet);
      }
    }
    if (
      !SelectedWalletAddress &&
      walletListDetails?.walletBalances?.length > 0
    ) {
      console.log(
        "Setting selected wallet address to the first wallet in the list"
      );
      setSelectedWalletAddress(
        walletListDetails?.walletBalances[0]?.walletAddress
      );
      setSelectedWalletDetails(walletListDetails?.walletBalances[0]);
    }
  }, [walletListDetails, navbarWalletAddress]);
  useEffect(() => {
    const Get = async () => {
      try {
        const response = await GetWalletBalance();
        setWalletListDetails(response);
      } catch (error) {}
    };
    Get();
  }, []);
  function getWalletLogo(walletID) {
    return walletLogos[walletID] || false;
  }
  return (
    <>
      <div className="investment-modal">
        <Modal
          className="investment-modal"
          open={isOpenModalBuySelected}
          centered
          closable={false}
          footer={null}
          width={560}
          onCancel={() => handleClose()}
          getContainer={false} // This renders the modal as a direct child of the Modal's parent, but might need adjustment based on your DOM structure
        >
          <div style={{ padding: "8px" }}>
            <div
              className="modal-close-icon"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <img
                src={close_icon}
                alt="close"
                onClick={() => handleClose()}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div style={{ padding: "0px 24px 32px 24px" }}>
              <div className="invest-modal-header">Buy</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "48px",
                  paddingBottom: "32px",
                }}
              >
                <div>
                  <div className="invest-modal-subheader">Loan ID</div>
                  <div className="invest-modal-subheader-value">
                    {formState?.loanId}
                  </div>
                </div>
                <div>
                  <div className="invest-modal-subheader">
                    Price and discount/premium
                  </div>
                  <div
                    className="invest-modal-subheader-value"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "8px",
                    }}
                  >
                    <AmountConverter value={formState?.principalAvailable} />
                    <div
                      className={`status-box-code status-box-code-label ${
                        formState?.smDiscountOrPremiumPercent > 0
                          ? "status-box-code-background-error"
                          : formState?.smDiscountOrPremiumPercent < 0
                          ? "status-box-code-background-success"
                          : formState?.smDiscountOrPremiumPercent == 0
                          ? "status-box-code-background-zero"
                          : ""
                      }`}
                    >
                      {formState?.smDiscountOrPremiumPercent == 0
                        ? "0,0"
                        : newNumberformat(
                            formState?.smDiscountOrPremiumPercent
                          )}{" "}
                      %
                    </div>
                  </div>
                </div>
                <div>
                  <div className="invest-modal-subheader">Interest rate</div>
                  <div className="invest-modal-subheader-value">
                    {newNumberformat(formState?.interestRatePercent)} %
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  paddingBottom: "32px",
                }}
              >
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  Amount to Buy
                </div>
                <div
                  className={`secondary-market-input-disabled secondary-market-input-disabled-font ${
                    insufficientBalance
                      ? "secondary-market-input-disabled-border"
                      : ""
                  }`}
                >
                  {/* error-icon-market-box errorIcon*/}
                  <span>{formState?.principalAvailable}</span>{" "}
                  {insufficientBalance ? (
                    <img src={errorIcon} alt="icon" />
                  ) : (
                    <img src={checkIcons} alt="icon" />
                  )}
                </div>
                <div
                  className={`${
                    insufficientBalance
                      ? "secondary-market-available-funds-labels-error"
                      : "secondary-market-available-funds-labels-normal"
                  }`}
                >
                  Linked Wallet Balance {SelectedStableCoin}:
                  {newNumberformat(SelectedWalletDetails?.walletBalance || 0.0)}
                </div>
              </div>
              <div
                style={{
                  paddingBottom: "32px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  Linked Wallet
                </div>
                {walletListDetails?.walletBalances?.length < 1 ? (
                  <Link
                    to={"/user/add-and-withdraw"}
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      className="div-navbar-wallet-connect"
                      style={{ width: "100%", height: "54px" }}
                    >
                      <div
                        style={{
                          fontSize: "16px",
                          lineHeight: "24px",
                          fontWeight: "400",
                        }}
                      >
                        Connect new wallet
                      </div>
                      <div>
                        <img src={add_circle} alt="add circle" />
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div className="custom-select-box new_select_normal_border">
                    <Select
                      placeholder={"Select wallet"}
                      className="filter-felid-width-style new_input_normal_border filter-select-style"
                      dropdownStyle={{ zIndex: 1200 }}
                      size={"large"}
                      style={{ width: "100%", height: "56px" }}
                      value={SelectedWalletAddress}
                      onChange={handleChange}
                      suffixIcon={<IoMdArrowDropdown color="black" size={14} />}
                      showSearch={false}
                    >
                      {walletListDetails?.walletBalances?.map((item) => (
                        <Option
                          key={item?.WalletAddress}
                          value={item?.walletAddress}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                            >
                              <>
                                {getWalletLogo(item?.walletName) ? (
                                  <img
                                    src={getWalletLogo(item?.walletName)}
                                    style={{
                                      width: "24px",
                                      height: "24px",
                                      borderRadius: "50%",
                                    }}
                                    alt="wallet logo"
                                  />
                                ) : (
                                  <MetaMaskAvatar
                                    address={item?.walletAddress}
                                    size={24}
                                  />
                                )}
                              </>
                              <span
                                style={{
                                  paddingLeft: "8px",
                                  paddingRight: "8px",
                                }}
                              >
                                {shortenAddress(item?.walletAddress)}
                              </span>
                              <span style={{ fontSize: "12px" }}>{`${
                                !getWalletLogo(item?.walletName)
                                  ? "(" + item?.walletName + ")"
                                  : ""
                              }`}</span>
                            </div>
                            <div>
                              <span
                                style={{ paddingRight: "8px" }}
                              >{`${newNumberformat(item.walletBalance)}`}</span>
                            </div>
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </div>
                )}
              </div>
              {/* <div style={{ paddingBottom:"32px" }} >
      <a 
      href={`/api/investor/public/agreement-preview?amount=${formState.amountToInvest || null}&loanId=${formState.loanId}`}
      target="_blank"
      style={{textDecoration: "none",}}
      >
      <div style={{display:"flex",flexDirection:"row",gap:"8px",}}>
        <div><img src={agreement_icon}></img></div><div style={{fontSize:"14px",color:"#EC441F", borderBottom: "1px solid #EC441F33" }}>Agreement Sample</div>
        </div>
      </a>
      </div> */}
              <div>
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  // loading={loader}
                  disabled={
                    SelectedWalletDetails?.walletBalance <
                    formState?.principalAvailable
                  }
                  className={`${
                    insufficientBalance
                      ? "secondary-market-button-disabled"
                      : "new_button_filled"
                  }`}
                  style={{
                    height: "48px",
                    width: "100%",
                    padding: "12px 24px",
                  }}
                  onClick={() => {
                    Buy();
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>Buy for</span>&nbsp;
                    <AmountConverter value={formState?.principalAvailable} />
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default SecondaryMarketTable;
