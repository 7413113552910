import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import close_icon from "../../Assets/images/IconButton.svg";
import { newNumberformat } from "../../Helper/HelperFunction";
import BarChartCard from "../../Components/Components/Atom/BarChartCard/BarChartCard";
import HeaderLabelTwo from "../../Components/Components/Atom/HeaderLabel/HeaderLabelTwo";
import DoughnutChartCard from "../../Components/Components/Atom/DoughnutChartCard/DoughnutChartCard";

export default function ChartComponent({
  open,
  setOpen,
  data,
  chartData,
  referenceData,
  investedFunds,
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  console.log("chart-data", chartData);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Determine the flex direction based on the window width
  const flexDirection = windowWidth <= 1150 ? "column" : "row";
  console.log(chartData?.amountByCountry?.labels);
  console.log(chartData?.amountByCountry?.values);

  // Process lenders data to show top 4 and "Others"
  const { labels: lenderLabels = [], values: lenderValues = [] } =
    chartData?.amountByOriginator || {};
  const combinedLendersData = lenderLabels.map((label, index) => ({
    label,
    value: lenderValues[index],
  }));
  const sortedLendersData = combinedLendersData.sort(
    (a, b) => b.value - a.value
  );
  const topLenders = sortedLendersData.slice(0, 4);
  const othersLenders = sortedLendersData.slice(4);
  const othersLendersSum = othersLenders.reduce(
    (sum, lender) => sum + lender.value,
    0
  );
  const finalLenderLabels = topLenders
    .map((lender) => lender.label)
    .concat(othersLenders.length > 0 ? ["Others"] : []);
  const finalLenderValues = topLenders
    .map((lender) => lender.value)
    .concat(othersLenders.length > 0 ? [othersLendersSum] : []);

  // Process countries data to show top 4 and "Others"
  const { labels: countryLabels = [], values: countryValues = [] } =
    chartData?.amountByCountry || {};
  const combinedCountriesData = countryLabels.map((label, index) => ({
    label,
    value: countryValues[index],
  }));
  const sortedCountriesData = combinedCountriesData.sort(
    (a, b) => b.value - a.value
  );
  const topCountries = sortedCountriesData.slice(0, 4);
  const othersCountries = sortedCountriesData.slice(4);
  const othersCountriesSum = othersCountries.reduce(
    (sum, country) => sum + country.value,
    0
  );
  const finalCountryLabels = topCountries
    .map((country) => country.label)
    .concat(othersCountries.length > 0 ? ["Others"] : []);
  const finalCountryValues = topCountries
    .map((country) => country.value)
    .concat(othersCountries.length > 0 ? [othersCountriesSum] : []);

  const updatedLoanStatusLabels = chartData?.amountByLoanStatus?.labels?.map(
    (label) => {
      if (label === "Current") {
        return "Active";
      } else if (
        ["late 1-15", "late 16-30", "late 31-60", "late 61+"].includes(label)
      ) {
        return "Delayed";
      } else {
        return label;
      }
    }
  );

  return (
    <>
      <Modal
        className="investment-modal"
        open={open}
        centered
        closable={false}
        footer={null}
        width={1136}
        onCancel={() => setOpen(false)}
        style={{}}
      >
        <div style={{ padding: "8px", minHeight: "840px" }}>
          <div
            className="modal-close-icon"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <img
              src={close_icon}
              alt="close"
              onClick={() => setOpen(false)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div style={{ padding: "0px 26px 32px 26px" }}>
            <div className="invest-modal-header">Charts</div>
            <div style={{ display: "flex", flexDirection: "row", gap: "48px" }}>
              <HeaderLabelTwo
                HeaderLabel="Deployed funds"
                data={newNumberformat(investedFunds || 0)}
              />
              <HeaderLabelTwo
                HeaderLabel="Claims"
                data={newNumberformat(data?.total || 0)}
              />
              <HeaderLabelTwo
                HeaderLabel="Total interest income"
                data={newNumberformat(data?.totalInterestReceived || 0)}
              />
              {/* <HeaderLabelTwo HeaderLabel="Total interest received" data={data?.totalInterestReceived||0}/> */}
            </div>
            <div style={{ height: "32px" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: flexDirection,
                gap: "24px",
              }}
            >
              <BarChartCard
                harder={"Interest"}
                labels={chartData?.amountByInterestRate?.labels}
                values={chartData?.amountByInterestRate?.values}
                footerLabel={"Weighted average:"}
                footerValue={`${newNumberformat(
                  chartData?.weightedAverageInterestRate || "0"
                )}%`}
              />
              <BarChartCard
                harder={"Maturity"}
                labels={chartData?.amountByRemainingTerm?.labels}
                values={chartData?.amountByRemainingTerm?.values}
                footerLabel={"Weighted average:"}
                footerValue={`${newNumberformat(
                  chartData?.weightedAverageRemainingTerm || "0"
                )} days`}
              />
            </div>
            <div style={{ height: "24px" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "24px",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <DoughnutChartCard
                label={"Loan status"}
                Chartlabels={updatedLoanStatusLabels}
                values={chartData?.amountByLoanStatus?.values}
              />
              <DoughnutChartCard
                label={"Lenders"}
                Chartlabels={finalLenderLabels}
                values={finalLenderValues}
              />
              <DoughnutChartCard
                label={"Countries"}
                Chartlabels={finalCountryLabels}
                values={finalCountryValues}
                referenceData={referenceData}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
