import React from "react";
import "./style.scss";
import AccountStatement from "../../Components/StatementPageComponents/AccountStatement/AccountStatement";
import {
  getAccountStatementData,
  getAccountStatementPDFData,
  getAccountStatementExcelData,
} from "../../services/accountStatementService";
import { message } from "antd";

export default function AccountStatementPage() {
  const fetchData = async (fetchFunction, params, returnOnly) => {
    try {
      const response = await fetchFunction(params);

      if (response.status === 200) {
        return returnOnly ? response.data : response;
      } else {
        message.error(response.status);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <AccountStatement
      getStatementData={(params) =>
        fetchData(getAccountStatementData, params, true)
      }
      getStatementPDFData={(params) =>
        fetchData(getAccountStatementPDFData, params, false)
      }
      getStatementExcelData={(params) =>
        fetchData(getAccountStatementExcelData, params, false)
      }
    />
  );
}
