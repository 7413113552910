import React from "react";
import "./style.scss";
import { Button, Flex } from "antd";
import ToDateIcon from "../../../Assets/images/end-date.svg";
import CustomChips from "../../../Components/Components/Atom/CustomChips/CustomChips";
import StatementDatePicker from "../../../Components/StatementPageComponents/StatementDatePicker/StatementDatePicker";

export default function StatementFilter({
  filter,
  setFilter,
  ButtonClick,
  loader,
  SelectDateFilter,
  activeChip,
}) {
  return (
    <>
      <div className="card card-style-statement filter-container">
        <div className="filter-header">Filter</div>
        <div className="filter-date-piker-container">
          <StatementDatePicker
            filter={filter}
            setFilter={setFilter}
            suffixIcon={ToDateIcon}
            className={"new_input new_input_normal_border date-picker-height"}
          />

          <div style={{ paddingTop: "32px", paddingBottom: "24px" }}>
            <Button
              style={{ width: "100%", height: "48px" }}
              className=""
              delay={1000}
              loading={loader}
              iconPosition="start"
              onClick={() => {
                ButtonClick();
              }}
            >
              {loader ? "" : "Confirm"}
            </Button>
          </div>
          <div>
            <Flex gap="8px 8px" wrap>
              <CustomChips
                label={"Today"}
                active={activeChip === "Today"}
                onClick={() => SelectDateFilter("Today")}
              />
              <CustomChips
                label={"Last 7 Days"}
                active={activeChip === "Last 7 Days"}
                onClick={() => SelectDateFilter("Last 7 Days")}
              />
              <CustomChips
                label={"This Month"}
                active={activeChip === "This Month"}
                onClick={() => SelectDateFilter("This Month")}
              />
              <CustomChips
                label={"Previous Month"}
                active={activeChip === "Previous Month"}
                onClick={() => SelectDateFilter("Previous Month")}
              />
              <CustomChips
                label={"Previous Year"}
                active={activeChip === "Previous Year"}
                onClick={() => SelectDateFilter("Previous Year")}
              />
              <CustomChips
                label={"All Time"}
                active={activeChip === "All Time"}
                onClick={() => SelectDateFilter("All Time")}
              />
            </Flex>
          </div>
        </div>
      </div>
    </>
  );
}
