import {
  message,
  Button,
  Table,
  Pagination,
  Select,
  Modal,
  Tooltip,
} from "antd";
import "./style.scss";
import SortArrows from "../../../UI-Library/SortArrows/SortArrows";
import CancelModal from "../CancelModal";
import React, { useEffect, useState } from "react";
import countries from "i18n-iso-countries";
import { HandelError, clientApi } from "../../../Utils/AxiosCall";
import ReactCountryFlag from "react-country-flag";
import { Link } from "react-router-dom";
import { newNumberformat } from "../../../Helper/HelperFunction";
import { MetaMaskAvatar } from "react-metamask-avatar";
import {
  IoIosInformationCircleOutline,
  IoMdArrowDropdown,
} from "react-icons/io";
import {
  discountOptions,
  PageSizeList1,
} from "../../../Helper_Data/SelectBoxData";
import { MdArrowDropDown } from "react-icons/md";
import close_icon from "../../../Assets/images/IconButton.svg";
import errorIcon from "../../../Assets/images/error-icon-market-box.svg";
import checkIcons from "../../../Assets/images/check-icon-invest.svg";
import InvestSellSuccessModal from "../../../Components/SuccessModal/InvestSellSuccessModal";
import { walletLogos } from "../../../Helper/CustomIcons";
// import close_icon from "../../../Assets/images/IconButton.svg";
// import errorIcon from "../../../Assets/images/error-icon-market-box.svg";
// import checkIcons from "../../../Assets/images/check-icon-invest.svg";
// import InvestSellSuccessModal from "../../SuccessModal/InvestSellSuccessModal";
import { DateParser } from "../../../Utils/common";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default function Tables({
  currencyCode,
  data,
  setPageSize,
  pageSize,
  totalItems,
  setPage,
  page,
  loader,
  GetMyPortfolio,
}) {
  const [openSellModal, setOpenSellModal] = useState(false);
  const [openSellSuccessModal, setOpenSellSuccessModal] = useState(false);
  const GetAgreement = async (fileReference) => {
    try {
      const response = await clientApi.get(
        `/api/guest/download/${fileReference}`,
        { responseType: "arraybuffer" }
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      let filename = "";
      const contentDisposition = response.headers["content-disposition"];
      if (contentDisposition) {
        const matches = contentDisposition.match(
          /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        );
        if (matches && matches[1]) {
          filename = matches[1].trim().replace(/['"]/g, ""); // Remove surrounding quotes and trim whitespace
        }
      }

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename || "Agreement"; // Set a default filename if not provided
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
      HandelError(error);
    }
  };
  function shortenAddress(data) {
    return `${data?.substring(0, 4)}...${data?.substring(data?.length - 4)}`;
  }
  function getWalletLogo(walletID) {
    return walletLogos[walletID] || false;
  }

  const columns = [
    {
      title: <div className="non-sorter-columns">Country</div>,
      dataIndex: "countryCode",
      key: "countryCode",
      align: "center",
      width: 101,
      render: (ele, key) => {
        const countryName = countries.getName(ele, "en", {
          select: "official",
        });
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <ReactCountryFlag countryCode={ele} svg />
            </div>
            <div>{countryName}</div>
          </div>
        );
      },
    },
    {
      title: <div className="non-sorter-columns">Investment id</div>,
      dataIndex: "investmentId",
      key: "investmentId",
      align: "center",
      width: 101,
    },
    {
      title: <div className="non-sorter-columns">Loan id</div>,
      dataIndex: "loanId",
      key: "loanId",
      align: "center",
      width: 101,
      render: (text, record) => (
        <div>
          <div className="" style={{ fontSize: "14px" }}>
            <Link
              className="primary-color"
              style={{ textDecoration: "none" }}
              to={`/user/loan/${record.loanId}`}
            >
              <span style={{ borderBottom: "1px solid #EC441F33" }}>
                {record.loanId}
              </span>
            </Link>{" "}
          </div>
          <div className="table-column-color" style={{ fontSize: "12px" }}>
            {record.productLabel}
          </div>
          <a
            style={{ textDecoration: "none", color: "black" }}
            onClick={() => GetAgreement(record.agreementFileReference)}
          >
            <span
              className="primary-color"
              style={{ fontSize: "12px", borderBottom: "1px solid #EC441F33" }}
            >
              Agreement
            </span>{" "}
          </a>
        </div>
      ),
    },
    {
      title: <div className="non-sorter-columns">Lender</div>,
      dataIndex: "originatorCompanyName",
      key: "originatorCompanyName",
      align: "center",
      width: 101,
    },
    {
      title: "Interest ",
      dataIndex: "interestRatePercent",
      key: "interestRatePercent",
      align: "center",
      width: 101,
      sorter: (a, b) => a.interestRatePercent - b.interestRatePercent,
      sortIcon: ({ sortOrder }) => <SortArrows sortOrder={sortOrder} />,
      render: (text, record) => (
        <div>
          <span>{newNumberformat(record.interestRatePercent) || 0}&nbsp;%</span>
        </div>
      ),
    },
    {
      title: "Deployed balance",
      dataIndex: "principalInvested",
      key: "principalInvested",
      align: "center",
      width: 101,
      sorter: (a, b) => a.principalInvested - b.principalInvested,
      sortIcon: ({ sortOrder }) => <SortArrows sortOrder={sortOrder} />,
      render: (text, record) => (
        <div>
          <span>{newNumberformat(record.principalInvested) || 0}</span>
        </div>
      ),
    },
    {
      title: "Deployment date",
      dataIndex: "investmentDate",
      key: "investmentDate",
      align: "center",
      width: 106.9,
      sorter: (a, b) =>
        DateParser(a.investmentDate) - DateParser(b.investmentDate),
      sortIcon: ({ sortOrder }) => <SortArrows sortOrder={sortOrder} />,
    },
    {
      title: <div className="non-sorter-columns">Linked wallet</div>,
      dataIndex: "LinkedWallet",
      key: "LinkedWallet",
      align: "center",
      width: 106.9,
      render: (text, record) => (
        <div>
          {record?.walletAddress ? (
            <span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "8px",
                }}
              >
                {getWalletLogo(record?.walletName) ? (
                  <img
                    src={getWalletLogo(record?.walletName)}
                    style={{
                      width: "24px",
                      height: "24px",
                      borderRadius: "50%",
                    }}
                    alt="wallet logo"
                  />
                ) : (
                  <MetaMaskAvatar address={record?.walletAddress} size={24} />
                )}
                {!getWalletLogo(record?.walletName) ? record?.walletName : ""}

                {shortenAddress(record?.walletAddress)}
              </div>
            </span>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      title: "Remaining duration",
      dataIndex: "termInDays",
      key: "termInDays",
      align: "center",
      width: 101,
      sorter: (a, b) => a.termInDays - b.termInDays,
      sortIcon: ({ sortOrder }) => <SortArrows sortOrder={sortOrder} />,
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {record.termInDays >= 0 ? (
            <>
              <div>
                {record.termPeriod ? (
                  <>
                    {record.termPeriod.years ? (
                      <>{record.termPeriod.years}y&nbsp;</>
                    ) : (
                      <></>
                    )}
                    {record.termPeriod.months ? (
                      <>{record.termPeriod.months}m&nbsp;</>
                    ) : (
                      <></>
                    )}
                    {record.termPeriod.days ? (
                      <>{record.termPeriod.days}d</>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : record.termInDays < 0 ? (
            <>
              <div class="text-danger-1">{record.termInDays}d</div>
            </>
          ) : (
            ""
          )}

          {/* <div className='table-column-color' >{record.collectionStatus}</div> */}
        </div>
      ),
    },
    {
      title: "Loan maturity date",
      dataIndex: "maturityDate",
      key: "maturityDate",
      align: "center",
      width: 106.9,
      sorter: (a, b) => DateParser(a.maturityDate) - DateParser(b.maturityDate),
      sortIcon: ({ sortOrder }) => <SortArrows sortOrder={sortOrder} />,
    },
    {
      title: <div className="non-sorter-columns">Collection Status</div>,
      dataIndex: "collectionStatus",
      key: "collectionStatus",
      align: "center",
      width: 106.9,
      render: (text, record) => (
        <div>
          <span>{record.collectionStatus}</span>
        </div>
      ),
    },
    // {
    //   title: 'Outstanding/ Invested',
    //   dataIndex: 'principalOutstanding',
    //   key: 'principalOutstanding',
    //   align: 'center',
    //   width:101,
    //   render: (text, record) => (
    //     <div >
    //       <span><AmountConverter value={record.principalOutstanding} /></span>
    //       <div className='table-column-color'><AmountConverter value={record.principalInvested} /></div>
    //     </div>
    //   ),
    // },
    {
      title: "Interest income",
      dataIndex: "interestReceived",
      key: "interestReceived",
      align: "center",
      width: 101,
      sorter: (a, b) => a.interestReceived - b.interestReceived,
      sortIcon: ({ sortOrder }) => <SortArrows sortOrder={sortOrder} />,
      render: (text, record) => (
        <div>
          <span>{newNumberformat(record.interestReceived)}</span>
        </div>
      ),
    },
    {
      title: (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            For&nbsp;Sale
            <Tooltip title="Sale price and discount/ premium">
              <IoIosInformationCircleOutline
                style={{ marginLeft: 4, cursor: "pointer" }}
                size={16}
              />
            </Tooltip>
          </div>
        </>
      ),
      dataIndex: "smOfferPrincipalAvailable",
      key: "smOfferPrincipalAvailable",
      align: "center",
      width: 101,
      sorter: (a, b) =>
        a.smOfferPrincipalAvailable - b.smOfferPrincipalAvailable,
      sortIcon: ({ sortOrder }) => <SortArrows sortOrder={sortOrder} />,
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            flexDirection: "column",
          }}
        >
          {record.smOfferPrincipalAvailable ? (
            <>
              <span>{newNumberformat(record.smPrice)} </span>
              <p style={{ fontSize: "12px", margin: "0" }}>
                {record.smDiscountOrPremiumPercent > 0 ? (
                  <>
                    <span style={{ color: "var(--success-color)" }}>
                      {record.smDiscountOrPremiumPercent
                        ? newNumberformat(record.smDiscountOrPremiumPercent)
                        : "0.0"}
                      %
                    </span>
                  </>
                ) : record.smDiscountOrPremiumPercent < 0 ? (
                  <>
                    <span class="text-danger-1">
                      {record.smDiscountOrPremiumPercent
                        ? newNumberformat(record.smDiscountOrPremiumPercent)
                        : "0.0"}
                      %
                    </span>
                  </>
                ) : (
                  "0.0%"
                )}
              </p>
            </>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "Action",
      key: "Action",
      align: "center",
      width: 65,
      render: (ele, key) => (
        <>
          {key.smOfferPrincipalAvailable > 0 ? (
            <Button
              onClick={() => {
                handleCancelModal(key);
              }}
              style={{ height: "40px", width: "91px" }}
              className="new_button_filled_cancel"
            >
              Cancel
            </Button>
          ) : key.principalOutstanding > 0 ? (
            <Button
              onClick={() => {
                handleSellModalOpen(key);
              }}
              style={{ height: "40px", width: "91px" }}
              className="new_button_filled-font-12px"
            >
              Sell
            </Button>
          ) : (
            <Button
              style={{ height: "40px", width: "91px" }}
              className="new_button_filled_disabled"
              disabled
            >
              Sold
            </Button>
          )}
        </>
      ),
    },
  ];
  const handlePageChange = async (pageNumber) => {
    await setPage(pageNumber);
  };

  const handlePageSizeChange = async (newSize) => {
    await setPageSize(newSize);
  };
  useEffect(() => {
    GetMyPortfolio();
  }, [page, pageSize]);

  const [amount, setAmount] = useState("");
  const [discount, setDiscount] = useState(0);
  const [investorId, setInvestorId] = useState("");
  const [errorSell, setErrorSell] = useState("");
  const [investorData, setInvestorData] = useState({}); // investment data of selected row
  const [initialAmount, setInitialAmount] = useState("0.00");
  const [openSellCancelsModal, setOpenSellCancelModal] = useState(false);

  const handleSellModalOpen = (data) => {
    console.log(data);
    setInvestorData(data);
    setInitialAmount(data.principalInvested);
    setAmount(data.principalInvested);
    setInvestorId(data.investmentId);
    setErrorSell("");
    setOpenSellModal(true);
  };
  const handleSellModalClose = () => {
    setAmount("");
    setDiscount(0);
    setInvestorId("");
    setOpenSellModal(false);
    setErrorSell("");
    setInvestorData({});
  };
  const handleSell = async () => {
    if (
      amount === "" ||
      amount === 0 ||
      amount === null ||
      amount === undefined ||
      amount === "0"
    ) {
      setErrorSell("Enter a valid amount");
      return;
    }
    if (Number(amount) > investorData?.principalInvested) {
      setErrorSell("maximum amount is " + investorData?.principalInvested);
      return;
    }

    try {
      const response = await clientApi.post("/api/investor/sell-investment", {
        investmentId: investorId,
        amount: amount,
        discountOrPremiumPercent: discount,
      });
      if (response?.status === 200) {
        handleSellModalClose();
        setOpenSellSuccessModal(true);
        GetMyPortfolio();
      }
    } catch (error) {
      if (
        error?.response?.data?.validation?.fieldErrors?.amount ===
        "INVALID_VALUE"
      ) {
        message.error("Enter valid amount");
      } else {
        HandelError(error);
      }
    }
  };
  const handleCancelModal = (data) => {
    setInvestorData(data);
    setOpenSellCancelModal(true);
  };
  const handleCancelModalClose = (data) => {
    setAmount("");
    setDiscount(0);
    setInvestorId("");
    setOpenSellCancelModal(false);
    setErrorSell("");
    setInvestorData({});
    setNewAmount(0);
  };
  const handleCancel = async (data) => {
    try {
      const response = await clientApi.post("/api/investor/sell-investment", {
        investmentId: investorData?.investmentId,
        amount: 0,
        discountOrPremiumPercent: 0,
      });
      if (response?.status === 200) {
        message.success("Cancellation successful");
        setOpenSellCancelModal(false);
        GetMyPortfolio();
      }
    } catch (error) {
      HandelError(error);
    }
  };
  const [disableButton, setDisableButton] = useState(true);
  const [newAmount, setNewAmount] = useState(0);
  const calculateAmount = () => {
    if (openSellModal) {
      const newAmount = amount + amount * (discount / 100);
      setNewAmount(newAmount);
      if (newAmount >= 10) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
        message.error(
          `Transactions must be valued at 10 ${currencyCode} or more. Please adjust the amount to proceed`
        );
      }
    }
  };

  useEffect(() => {
    calculateAmount(discount);
  }, [amount, discount, openSellModal]);

  return (
    <div className="">
      {console.log("data", data)}
      <Table
        columns={columns}
        dataSource={data}
        loading={loader}
        responsive
        scroll={{ x: "max-content" }}
        pagination={false}
        showSizeChanger
        className="custom-table custom-padding-bottom-24"
        showSorterTooltip={{
          title: null,
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop: "24px",
          paddingBottom: "24px",
        }}
      >
        <div>
          <Pagination
            current={page}
            pageSize={pageSize}
            total={totalItems}
            onChange={handlePageChange}
            onShowSizeChange={handlePageSizeChange}
            className="custom-pagination"
            pageSizeOptions={["10", "20", "50", "100"]}
            showSizeChanger={false}
          />
        </div>
        <div className="new_input_normal_border_pagination ">
          <Select
            placeholder={"All"}
            className="new_input_normal_border_pagination"
            options={PageSizeList1}
            style={{ width: "100%", height: "40px", paddingRight: "8px" }}
            dropdownStyle={{ zIndex: 1200 }}
            suffixIcon={<MdArrowDropDown color="black" size={24} />}
            // size={"large"}
            value={pageSize}
            onChange={handlePageSizeChange}
          />
        </div>
      </div>

      <Modal
        className="investment-modal"
        open={openSellModal}
        centered
        closable={false}
        footer={null}
        width={560}
        onCancel={() => handleSellModalClose()}
        style={{}}
      >
        <div style={{ padding: "8px", minHeight: "472px" }}>
          <div
            className="modal-close-icon"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <img
              src={close_icon}
              alt="close"
              onClick={() => handleSellModalClose()}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div style={{ padding: "0px 26px 32px 26px" }}>
            <div className="invest-modal-header">Sell</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "48px",
                paddingBottom: "32px",
              }}
            >
              <div>
                <div className="invest-modal-subheader">Loan ID</div>
                <div className="invest-modal-subheader-value">
                  {investorData?.loanId}
                </div>
              </div>
              <div>
                <div className="invest-modal-subheader">Deployment date</div>
                <div className="invest-modal-subheader-value">
                  {investorData?.investmentDate}
                </div>
              </div>
              <div>
                <div className="invest-modal-subheader">Interest </div>
                <div className="invest-modal-subheader-value">
                  {newNumberformat(investorData?.interestRatePercent)} %
                </div>
              </div>
            </div>
            <div className="custom-select-box">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 ">Amount to Sell:</div>
                    <div className="col-12" style={{ paddingTop: "4px" }}>
                      {/* <Input style={{ width: "100%", height: "56px" }} className='new_input_filter new_input_normal_border' placeholder='Amount'
                        onKeyPress={(e) => {
                          if (!/[0-9.]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          // Regular expression to match only numeric values
                          const regex = /^[0-9]*\.?[0-9]*$/;
                          if (regex.test(inputValue)) {
                            setAmount(inputValue);
                          }
                        }}
                         value={amount}
                      ></Input> */}
                      <div
                        className={`secondary-market-input-disabled secondary-market-input-disabled-font ${
                          disableButton
                            ? "secondary-market-input-disabled-border"
                            : ""
                        }`}
                      >
                        {/* error-icon-market-box errorIcon*/}
                        <span>{newNumberformat(amount)}</span>{" "}
                        {disableButton ? (
                          <img src={errorIcon} alt="icon" />
                        ) : (
                          <img src={checkIcons} alt="icon" />
                        )}
                      </div>
                    </div>
                    {/* <div style={{fontWeight:"12px",fontWeight:"400",lineHeight:"16px",letterSpacing:"0.3px",color:"#161616B2",paddingTop:"4px"}}>
                    Amount deployed {initialAmount||"0,00"}
          </div> */}
                  </div>
                </div>
                <div className="col-12 custom-padding-top-24">
                  <div className="row">
                    <div className="col-12">Discount or Premium</div>
                    <div
                      className="col-12 custom-select-box new_select_normal_border"
                      style={{ paddingTop: "4px" }}
                    >
                      <Select
                        style={{ width: "100%", height: "56px" }}
                        options={discountOptions}
                        className="filter-felid-width-style new_input_normal_border filter-select-style"
                        value={discount}
                        defaultValue={0}
                        dropdownStyle={{ zIndex: 1200 }}
                        onChange={(e) => {
                          // calculateAmount(e);
                          setDiscount(e);
                        }}
                        suffixIcon={
                          <IoMdArrowDropdown color="black" size={14} />
                        }
                      ></Select>
                    </div>
                  </div>
                </div>
              </div>
              {errorSell ? (
                <p className="custom-padding-top-24" style={{ color: "red" }}>
                  {errorSell}
                </p>
              ) : (
                ""
              )}

              <div className="row" style={{ paddingTop: "40px" }}>
                <div className="col-md-12">
                  <Button
                    type="primary"
                    block
                    htmlType="submit"
                    // loading={loader}
                    classNames={`${
                      disableButton
                        ? "new_button_filled_disabled"
                        : "new_button_filled"
                    }`}
                    // className='new_button_filled'
                    style={{ height: "48px" }}
                    onClick={() => {
                      handleSell();
                    }}
                    disabled={disableButton}
                  >
                    Sell for {newNumberformat(newAmount)}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <CancelModal
        isOpen={openSellCancelsModal}
        handleCancel={handleCancelModalClose}
        callBack={handleCancel}
        data={investorData}
      />
      <InvestSellSuccessModal
        status={openSellSuccessModal}
        setStatus={setOpenSellSuccessModal}
      />
    </div>
  );
}
