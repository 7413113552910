import { GlobalOutlined } from "@ant-design/icons";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //   value: 0,
  pageTopLoader: {
    value: 0,
  },
  walletAddress: "",
  USDTprice: 0,
  USDCprice: 0,
  SelectedStableCoin: "USDT", // Ensure it's initialized here
  LoginToken: "",
  DepositTransactionStatus: "first",
  WithdrawalTransactionStatus: "first",
  currencyCode: "USDT",
  TwoFactorAuthenticationStatus: false,
  GlobalProfileData: {},
  SelectedWalletAddress: "",
  collapsed: false,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    pageTopLoader: (state, { payload }) => {
      state.pageTopLoader = payload;
    },
    walletAddress: (state, { payload }) => {
      state.walletAddress = payload;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
    USDTprice: (state, { payload }) => {
      state.USDTprice = payload;
    },
    USDCprice: (state, { payload }) => {
      state.USDCprice = payload;
    },
    setSelectedStableCoin: (state, { payload }) => {
      state.SelectedStableCoin = payload;
    },
    LoginToken: (state, { payload }) => {
      state.LoginToken = payload;
    },
    setDepositTransactionStatus: (state, { payload }) => {
      state.DepositTransactionStatus = payload;
    },
    setWithdrawalTransactionStatus: (state, { payload }) => {
      state.WithdrawalTransactionStatus = payload;
    },
    setCurrencyCode: (state, { payload }) => {
      state.currencyCode = payload;
    },
    setTwoFactorAuthenticationStatus: (state, { payload }) => {
      state.TwoFactorAuthenticationStatus = payload;
    },
    setGlobeProfileData: (state, { payload }) => {
      state.GlobalProfileData = payload;
    },
    setSelectedWalletAddress: (state, { payload }) => {
      state.SelectedWalletAddress = payload;
    },
    setCollapsed: (state, { payload }) => {
      state.collapsed = payload;
    },
  },
});

export const {
  pageTopLoader,
  walletAddress,
  incrementByAmount,
  USDTprice,
  USDCprice,
  SelectedStableCoin,
  setSelectedStableCoin,
  LoginToken,
  setDepositTransactionStatus,
  DepositTransactionStatus,
  WithdrawalTransactionStatus,
  setWithdrawalTransactionStatus,
  currencyCode,
  setCurrencyCode,
  TwoFactorAuthenticationStatus,
  setTwoFactorAuthenticationStatus,
  setGlobeProfileData,
  GlobalProfileData,
  setSelectedWalletAddress,
  SelectedWalletAddress,
  setCollapsed,
  collapsed,
} = globalSlice.actions;

export default globalSlice.reducer;
