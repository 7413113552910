import React, { useState, useEffect } from "react";
import "./style.css";
import { Button, Input, message } from "antd";
import { LuFileText } from "react-icons/lu";
import {
  GetWalletBalance,
  HandelError,
  clientApi,
} from "../../../Utils/AxiosCall";
import {
  StablecoinToUSDConverter,
  formatNumber,
  newNumberformat,
} from "../../../Helper/HelperFunction";
import { Modal } from "react-bootstrap";
import InvestorVeriffModal from "../../InvestorNotVerifiedBox/InvestorVeriffModal";
import InvestingOnHold from "../../StatusModels/InvestingOnHold";
import InvestSuccessModal from "../../SuccessModal/InvestSuccessModal";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import { WalletTestData } from "../../../Helper_Data/SelectBoxData";
import { IoMdArrowDropdown } from "react-icons/io";
import { Select } from "antd";
import { MetaMaskAvatar } from "react-metamask-avatar";
import add_circle from "../../../Assets/images/add-circle-outline.svg";
import { Link } from "react-router-dom";
const { Option } = Select;
export default function LoanDetailsInvest({
  holdInvesting,
  cashBalance,
  currencySymbol,
  loanId,
  GetCallaAllFunction,
  AmountConverter,
}) {
  const GlobalProfileData = useSelector(
    (state) => state.global.GlobalProfileData
  );
  const SelectedStableCoin = useSelector(
    (state) => state?.global?.SelectedStableCoin
  );
  const [SelectedWalletAddress, setSelectedWalletAddress] = useState("");
  const [SelectedWalletDetails, setSelectedWalletDetails] = useState({});
  const [walletListDetails, setWalletListDetails] = useState({});
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false); // used for hold invest modal
  const [isOpenVeriffModal, setIsOpenVeriffModal] = useState(false);
  const [veriffStatus, setVeriffStatus] = useState("false");
  const [loneID, setLoneID] = useState(loanId);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const handleInvest = async () => {
    if (veriffStatus === "NOT_VERIFIED") {
      return setIsOpenVeriffModal(true);
    }
    if (holdInvesting) {
      return setIsOpenModal(true);
    }
    if (amount <= 0 || amount === "" || amount === null) {
      message.error("Invalid Amount");
      setError("Invalid Amount");
      return;
    }
    if (amount < 10) {
      message.error("Minimum amount to invest is 10");
      setError("Minimum amount to invest is 10");
      return;
    }
    if (amount > SelectedWalletDetails?.walletBalance) {
      setError(
        "Amount to invest should be less than or equal to available amount in linked wallet"
      );
      message.error(
        "Amount to invest should be less than or equal to available amount in linked wallet"
      );
      return;
    }
    try {
      const amounts = amount;
      const response = await clientApi.post(`/api/investor/invest`, {
        loanId: loanId,
        amount: amounts,
        walletAddress: SelectedWalletAddress,
      });
      if (response?.status === 200) {
        setOpenSuccessModal(true);
        setAmount("");
        setError("");
        GetCallaAllFunction();
      }

      console.log(response);
    } catch (error) {
      if (
        error?.response?.data?.validation?.fieldErrors?.amount ===
        "INVALID_VALUE"
      ) {
        message.error("Invalid Amount");
        setError("Invalid Amount");
      } else if (error?.response?.data?.validation?.errors[0]) {
        message.error(error?.response?.data?.validation?.errors[0]);
        setError(error?.response?.data?.validation?.errors[0]);
      } else {
        message.error("Investment Failed");
      }
      HandelError(error);
    }
  };

  useEffect(() => {
    // GetProfile()
    const GetData = async () => {
      const response = await localStorage.getItem("investorStatus");
      setVeriffStatus(response);
    };
    GetData();
  }, []);

  const shortenAddress = (address) => {
    return `${address?.substring(0, 4)}...${address?.substring(
      address?.length - 4
    )}`;
  };
  const handleChange = (value) => {
    const addressToUse = value;
    setSelectedWalletAddress(addressToUse);
    const selectedItem = walletListDetails?.walletBalances?.find(
      (item) => item.walletAddress === addressToUse
    );
    console.log(selectedItem);
    setSelectedWalletDetails(selectedItem);
  };

  const navbarWalletAddress = useSelector(
    (state) => state.global.SelectedWalletAddress
  );

  useEffect(() => {
    console.log("walletListDetails:", walletListDetails);
    console.log("navbarWalletAddress:", navbarWalletAddress);
    console.log("SelectedWalletAddress:", SelectedWalletAddress);

    if (navbarWalletAddress && walletListDetails) {
      const selectedWallet = walletListDetails?.walletBalances?.find(
        (wallet) => wallet.walletAddress === navbarWalletAddress
      );
      console.log("selectedWallet:", selectedWallet);
      if (selectedWallet) {
        console.log(
          "Setting selected wallet address to navbarWalletAddress:",
          navbarWalletAddress
        );
        setSelectedWalletAddress(navbarWalletAddress);
        setSelectedWalletDetails(selectedWallet);
      }
    }
    if (
      !SelectedWalletAddress &&
      walletListDetails?.walletBalances?.length > 0
    ) {
      console.log(
        "Setting selected wallet address to the first wallet in the list"
      );
      setSelectedWalletAddress(
        walletListDetails?.walletBalances[0]?.walletAddress
      );
      setSelectedWalletDetails(walletListDetails?.walletBalances[0]);
    }
  }, [walletListDetails, navbarWalletAddress, SelectedWalletAddress]);
  useEffect(() => {
    const Get = async () => {
      try {
        const response = await GetWalletBalance();
        setWalletListDetails(response);
      } catch (error) {}
    };
    Get();
  }, []);
  return (
    <>
      <div className="custom-card-lone-details">
        <div className=" card" style={{ display: "flex", border: "none" }}>
          <div className="" style={{}}>
            {
              <>
                <div className="col-12">
                  <span className="loan-details-card-heading">Buy</span>
                </div>
                <div className="loan-invest-divider"></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    paddingBottom: "32px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    Amount to buy
                  </div>
                  <div>
                    <Input
                      style={{ width: "100%", height: "56px" }}
                      className="new_input_normal_border"
                      placeholder={`Enter sum`}
                      value={amount || ""}
                      onKeyPress={(e) => {
                        if (!/[0-9.]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        // Regular expression to match only numeric values
                        const regex = /^[0-9]*\.?[0-9]*$/;
                        if (regex.test(inputValue)) {
                          setAmount(inputValue);
                        }
                      }}
                    />
                  </div>
                  <div
                    style={{
                      fontWeight: "12px",
                      fontWeight: "400",
                      lineHeight: "16px",
                      letterSpacing: "0.3px",
                      color: "#161616B2",
                    }}
                  >
                    Linked wallet balance {SelectedStableCoin}:
                    {newNumberformat(SelectedWalletDetails?.walletBalance || 0)}
                  </div>
                </div>
                <div
                  style={{
                    paddingBottom: "32px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    Linked Wallet
                  </div>
                  {walletListDetails?.walletBalances?.length < 1 ? (
                    <Link
                      to={"/user/add-and-withdraw"}
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        className="div-navbar-wallet-connect"
                        style={{ width: "100%", height: "54px" }}
                      >
                        <div
                          style={{
                            fontSize: "16px",
                            lineHeight: "24px",
                            fontWeight: "400",
                          }}
                        >
                          Connect new wallet
                        </div>
                        <div>
                          <img src={add_circle} alt="add circle" />
                        </div>
                      </div>
                    </Link>
                  ) : (
                    <div className="custom-select-box new_select_normal_border">
                      <Select
                        placeholder={"Select wallet"}
                        className="filter-felid-width-style new_input_normal_border filter-select-style"
                        dropdownStyle={{ zIndex: 1200 }}
                        size={"large"}
                        style={{ width: "100%", height: "56px" }}
                        value={SelectedWalletAddress}
                        onChange={handleChange}
                        suffixIcon={
                          <IoMdArrowDropdown color="black" size={14} />
                        }
                        showSearch={false}
                      >
                        {walletListDetails?.walletBalances?.map((item) => (
                          <Option
                            key={item?.WalletAddress}
                            value={item?.walletAddress}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexDirection: "row",
                                }}
                              >
                                <MetaMaskAvatar
                                  address={item?.walletAddress}
                                  size={24}
                                />
                                <span
                                  style={{
                                    paddingLeft: "8px",
                                    paddingRight: "8px",
                                  }}
                                >
                                  {shortenAddress(item?.walletAddress)}
                                </span>
                                <span
                                  style={{ fontSize: "10px" }}
                                >{`(${item.walletName})`}</span>
                              </div>
                              <div>
                                <span
                                  style={{ paddingRight: "8px" }}
                                >{`${newNumberformat(
                                  item.walletBalance
                                )}`}</span>
                              </div>
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </div>
                  )}
                </div>
                {/* <div className="loan-invest-divider"></div> */}
                {/* <div className="col-12">
                        <a href={`/api/investor/public/agreement-preview?amount=${amount||null}&loanId=${loanId}`} style={{textDecoration:"none"}} target='_blank' >
                        <span style={{cursor:"pointer",color:"#EC441F",fontSize:"14px"  }}><LuFileText style={{marginRight:"12px"}} />Agreement Sample </span>
                        </a>
                    </div> */}
                <div
                  className="loan-invest-divider d-flex align-items-center"
                  style={{ color: "tomato" }}
                >
                  {/* <span>{error}</span> */}
                </div>
                {GlobalProfileData?.investorStatus === "NOT_VERIFIED" ||
                GlobalProfileData?.investorStatus === "REGISTRATION" ? (
                  <Tooltip title="Verification under Progress">
                    <Button
                      className="new_button_filled"
                      style={{ width: "100%", height: "52px" }}
                    >
                      Buy {amount ? newNumberformat(amount) : ""}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    onClick={() => handleInvest()}
                    className="new_button_filled"
                    style={{ width: "100%", height: "52px" }}
                  >
                    Buy {amount ? newNumberformat(amount) : ""}
                  </Button>
                )}
              </>
            }
          </div>
        </div>
      </div>

      <InvestingOnHold isOpen={isOpenModal} setIsOpen={setIsOpenModal} />
      {/* <InvestorVeriffModal isOpened={isOpenVeriffModal} setIsOpened={setIsOpenVeriffModal}/> */}
      <InvestSuccessModal
        status={openSuccessModal}
        setStatus={setOpenSuccessModal}
        data={loneID}
        setData={setLoneID}
      />
    </>
  );
}
