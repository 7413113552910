import React, { useState, useEffect } from "react";
import "./style.css";
import Modal from "react-bootstrap/Modal";
import { Button, Card, Checkbox, Input, Select, message } from "antd";
import {
  Loan_extensions_list,
  WalletTestData,
} from "../../Helper_Data/SelectBoxData";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import filter_icon from "../../Assets/images/filter_list.svg";
import close_icon from "../../Assets/images/IconButton.svg";
import { FiChevronDown } from "react-icons/fi";
import { IoMdArrowDropdown } from "react-icons/io";
import { VscPercentage } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { GetWalletBalance } from "../../Utils/AxiosCall";
const { Option } = Select;
export default function MyPortfolioFilterNew({
  filters,
  setFilters,
  isModalOpen,
  setIsModalOpen,
  countries_list,
  originators_list,
  collectionStatuses_list,
  products_list,
  handleSearch,
  totalCount,
  clearFlitter,
  ResetData,
  ListALLData,
  labelStatus,
}) {
  const [searchStatus, setSearchStatus] = useState(true);
  const ListAll = async () => {
    await setFilters({
      autoInvestId: null,
      collectionStatuses: [],
      countries: [],
      currencyCode: filters?.currencyCode || "USD",
      extensions: null,
      forSale: false,
      interestRatePercentFrom: null,
      interestRatePercentTo: null,
      investStrategyId: null,
      loanId: null,
      originators: [],
      products: [],
      remainingTermInDaysFrom: null,
      remainingTermInDaysTo: null,
      showActive: true,
      showClosed: true,
      walletId: "",
    });
    setSearchStatus(false);
    await handleSearch();
  };
  const handleClose = async () => {
    // setIsModalOpen(false);
    setSearchStatus(false);
    // await clearFlitter();
    // await handleSearch();
    await ListALLData();
  };
  const [show, setShow] = useState(true);
  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      if (show) {
        setShow(false);
      } else {
        setShow(true);
      }
    });
    console.log(totalCount, "totalCount");

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "72px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: "#161616B2",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: "400",
            }}
          >
            {labelStatus?.forSale
              ? "Filtered investments :"
              : labelStatus?.active && labelStatus?.closed
              ? "Filtered investments :"
              : labelStatus?.active
              ? "Ongoing claims:"
              : labelStatus?.closed
              ? "Filtered investments"
              : "All Investments:"}
          </div>
          <div
            style={{
              color: "#242424",
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: "700",
            }}
          >
            {totalCount?.total || 0}
            {/* {totalCount?.defaultTotal || 0} */}
          </div>
          {totalCount?.total !== totalCount.defaultTotal && (
            <>
              <div
                style={{
                  color: "#161616B2",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                {searchStatus && <>/</>}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {searchStatus && (
                  <>
                    <div
                      onClick={() => handleClose()}
                      style={{
                        height: "24px",
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: "700",
                        color: "#EC441F",
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #EC441F33",
                        cursor: "pointer",
                      }}
                    >
                      {totalCount.defaultTotal || 0}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <div onClick={decoratedOnClick} className="filter-button-style">
          <>
            {" "}
            <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
              <div>{!show ? "Close filters" : "Show filters"}</div>
              <div>
                {!show ? (
                  <img src={close_icon} alt="Close filters" />
                ) : (
                  <img src={filter_icon} alt="Show filters" />
                )}
              </div>{" "}
            </div>
          </>
        </div>
      </div>
    );
  }
  const SelectedStableCoin = useSelector(
    (state) => state?.global?.SelectedStableCoin
  );
  const [SelectedWalletAddress, setSelectedWalletAddress] = useState([]);
  const [SelectedWalletDetails, setSelectedWalletDetails] = useState({});
  const shortenAddress = (address) => {
    return `${address?.substring(0, 4)}...${address?.substring(
      address?.length - 4
    )}`;
  };
  const handleChange = (value) => {
    setSelectedWalletAddress(value);
    setFilters({ ...filters, walletAddresses: value });
    const selectedItem = walletListDetails?.walletBalances?.find(
      (item) => item.walletAddress === value
    );
    setSelectedWalletDetails(selectedItem);
  };
  const [walletListDetails, setWalletListDetails] = useState({});
  useEffect(() => {
    const Get = async () => {
      try {
        const response = await GetWalletBalance();
        console.log(response);
        setWalletListDetails(response);
      } catch (error) {}
    };
    Get();
  }, []);
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ width: "100%" }}>
        <Accordion className="custom-card">
          <div className="custom-card" style={{ background: "#FFFFFFA6" }}>
            <CustomToggle eventKey="0"></CustomToggle>
            <Accordion.Collapse eventKey="0">
              <div className="filter-flex-column-container">
                <div className="filter-flex-row-container">
                  <div className="filter-flex-column-small-gap filter-felid-width-style">
                    <div className="filter-product-label-style">Products</div>
                    <div className="custom-select-box new_select_normal_border">
                      <Select
                        mode="multiple"
                        placeholder={"All"}
                        className=" new_input_normal_border filter-select-style"
                        options={products_list || []}
                        size="large"
                        style={{ width: "100%" }}
                        dropdownStyle={{ zIndex: 1200 }}
                        value={filters?.products}
                        onChange={(e) => {
                          setFilters({ ...filters, products: e });
                        }}
                        suffixIcon={
                          <IoMdArrowDropdown color="black" size={14} />
                        }
                        // showSearch
                        // filterOption={(input, option) =>
                        //   option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        // }
                        showSearch={false}
                      />
                    </div>
                  </div>
                  <div className="filter-flex-column-small-gap filter-felid-width-style">
                    <div className="filter-product-label-style">Countries</div>
                    <div className="custom-select-box new_select_normal_border">
                      <Select
                        mode="multiple"
                        placeholder={"All"}
                        className="filter-felid-width-style new_input_normal_border filter-select-style"
                        options={countries_list || []}
                        dropdownStyle={{ zIndex: 1200 }}
                        style={{ width: "100%" }}
                        size={"large"}
                        value={filters?.countries}
                        onChange={(e) => {
                          setFilters({ ...filters, countries: e });
                        }}
                        suffixIcon={
                          <IoMdArrowDropdown color="black" size={14} />
                        }
                        showSearch={false}
                      />
                    </div>
                  </div>
                  <div className="filter-flex-column-small-gap filter-felid-width-style">
                    <div className="filter-product-label-style">Lender</div>
                    <div className="custom-select-box new_select_normal_border">
                      <Select
                        mode="multiple"
                        placeholder={"All"}
                        className="filter-felid-width-style new_input_normal_border filter-select-style"
                        options={originators_list || []}
                        dropdownStyle={{ zIndex: 1200 }}
                        style={{ width: "100%" }}
                        size={"large"}
                        value={filters?.originators}
                        onChange={(e) => {
                          setFilters({ ...filters, originators: e });
                        }}
                        suffixIcon={
                          <IoMdArrowDropdown color="black" size={14} />
                        }
                        showSearch={false}
                      />
                    </div>
                  </div>
                  <div className="filter-flex-column-small-gap filter-felid-width-style">
                    <div className="filter-product-label-style">Status</div>
                    <div className="custom-select-box new_select_normal_border">
                      <Select
                        placeholder={"All"}
                        className="filter-felid-width-style new_input_normal_border filter-select-style"
                        options={collectionStatuses_list || []}
                        dropdownStyle={{ zIndex: 1200 }}
                        style={{ width: "100%" }}
                        size={"large"}
                        value={filters?.collectionStatuses}
                        onChange={(e) => {
                          setFilters({
                            ...filters,
                            collectionStatuses: Array.isArray(e) ? e : [e],
                          });
                        }}
                        suffixIcon={
                          <IoMdArrowDropdown color="black" size={14} />
                        }
                        showSearch={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="filter-flex-row-container">
                  <div className="filter-flex-column-small-gap filter-felid-width-style">
                    <div className="filter-product-label-style">Extension</div>
                    <div className="custom-select-box new_select_normal_border">
                      <Select
                        placeholder={"All"}
                        className="filter-felid-width-style new_input_normal_border filter-select-style"
                        options={Loan_extensions_list || []}
                        dropdownStyle={{ zIndex: 1200 }}
                        style={{ width: "100%" }}
                        size={"large"}
                        value={filters?.extensions}
                        onChange={(e) => {
                          setFilters({ ...filters, extensions: e });
                        }}
                        suffixIcon={
                          <IoMdArrowDropdown color="black" size={14} />
                        }
                        showSearch={false}
                      />
                    </div>
                  </div>
                  <div className="filter-flex-column-small-gap filter-felid-width-style">
                    <div className="filter-product-label-style">Interest </div>
                    <div
                      className=""
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                      }}
                    >
                      <div>
                        <Input
                          style={{ width: "100%", height: "40px" }}
                          className="new_input_filter new_input_normal_border"
                          placeholder="From "
                          onKeyPress={(e) => {
                            if (!/[0-9.]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filters?.interestRatePercentFrom}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*\.?[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilters({
                                ...filters,
                                interestRatePercentFrom: inputValue,
                              });
                            }
                          }}
                          suffix={<VscPercentage color="black" size={14} />}
                        ></Input>
                      </div>
                      <div>
                        <Input
                          style={{ width: "100%", height: "40px" }}
                          className="new_input new_input_filter new_input_normal_border"
                          placeholder="To "
                          onKeyPress={(e) => {
                            if (!/[0-9.]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filters?.interestRatePercentTo}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*\.?[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilters({
                                ...filters,
                                interestRatePercentTo: inputValue,
                              });
                            }
                          }}
                          suffix={<VscPercentage color="black" size={14} />}
                        ></Input>
                      </div>
                    </div>
                  </div>
                  <div className="filter-flex-column-small-gap filter-felid-width-style">
                    <div className="filter-product-label-style">
                      Remaining&nbsp;loan&nbsp;maturity&nbsp;in&nbsp;days
                    </div>
                    <div
                      className=""
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <Input
                          style={{ width: "100%", height: "40px" }}
                          className=" new_input_filter new_input_normal_border"
                          placeholder="From "
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filters?.remainingTermInDaysFrom}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilters({
                                ...filters,
                                remainingTermInDaysFrom: inputValue,
                              });
                            }
                          }}
                        ></Input>
                      </div>
                      <div style={{ width: "100%" }}>
                        <Input
                          style={{ width: "100%", height: "40px" }}
                          className=" new_input_filter new_input_normal_border"
                          placeholder="To "
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filters?.remainingTermInDaysTo}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilters({
                                ...filters,
                                remainingTermInDaysTo: inputValue,
                              });
                            }
                          }}
                        ></Input>
                      </div>
                    </div>
                  </div>
                  <div className="filter-flex-column-small-gap filter-felid-width-style">
                    <div className="filter-product-label-style">
                      Loan&nbsp;duration&nbsp;in&nbsp;days
                    </div>
                    <div
                      className=""
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <Input
                          style={{ width: "100%", height: "40px" }}
                          className=" new_input_filter new_input_normal_border"
                          placeholder="From "
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filters?.loanDurationInDaysFrom}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilters({
                                ...filters,
                                loanDurationInDaysFrom: inputValue,
                              });
                            }
                          }}
                        ></Input>
                      </div>
                      <div style={{ width: "100%" }}>
                        <Input
                          style={{ width: "100%", height: "40px" }}
                          className=" new_input_filter new_input_normal_border"
                          placeholder="To "
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filters?.loanDurationInDaysTo}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilters({
                                ...filters,
                                loanDurationInDaysTo: inputValue,
                              });
                            }
                          }}
                        ></Input>
                      </div>
                    </div>
                  </div>
                  <div className="filter-flex-column-small-gap filter-felid-width-style">
                    <div className="filter-product-label-style">
                      Linked Wallet
                    </div>
                    <div
                      className="custom-select-box new_select_normal_border"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                        width: "100%",
                      }}
                    >
                      <Select
                        mode="multiple"
                        placeholder="All wallets"
                        className="filter-felid-width-style new_input_normal_border filter-select-style"
                        dropdownStyle={{ zIndex: 1200 }}
                        style={{ width: "100%" }}
                        size="large"
                        value={filters?.walletAddresses || undefined}
                        // onChange={handleChange}
                        onChange={(e) => {
                          setFilters({ ...filters, walletAddresses: e });
                        }}
                        suffixIcon={
                          <IoMdArrowDropdown color="black" size={14} />
                        }
                        showSearch={false}
                      >
                        {walletListDetails?.walletBalances?.map((item) => (
                          <Option key={item?.walletId} value={item?.walletId}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <span>{shortenAddress(item?.walletAddress)}</span>
                              <span
                                style={{
                                  paddingLeft: "8px",
                                  fontSize: "10px",
                                  fontWeight: "300",
                                }}
                              >
                                {`(${item.walletName})`}
                              </span>
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  {/* <div className="filter-flex-column-small-gap">
                    <div className="filter-product-label-style"></div>
                    <div className='' style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                      <div>
                        <Input style={{ width: "125px", height: "38px" }} className='new_input_filter new_input_normal_border' placeholder='From '
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filters?.principalOfferFrom}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilters({ ...filters, principalOfferFrom: inputValue });
                            }
                          }}
                        ></Input>
                      </div>
                      <div >
                        <Input style={{ width: "125px", height: "38px" }} className='new_input new_input_filter new_input_normal_border' placeholder='To '
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filters?.principalOfferTo}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilters({ ...filters, principalOfferTo: inputValue });
                            }
                          }}
                        ></Input>
                      </div>
                    </div>
                  </div> */}
                </div>

                <div
                  style={{ display: "flex", flexDirection: "row", gap: "16px" }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#161616B2",
                    }}
                  >
                    Display only:
                  </div>
                  <div>
                    <Checkbox
                      checked={filters.showActive}
                      onChange={() =>
                        setFilters({
                          ...filters,
                          showActive: !filters.showActive,
                        })
                      }
                      style={{ marginRight: "12px" }}
                    ></Checkbox>
                    Ongoing
                  </div>
                  <div>
                    <Checkbox
                      checked={filters.showClosed}
                      onChange={() =>
                        setFilters({
                          ...filters,
                          showClosed: !filters.showClosed,
                        })
                      }
                      style={{ marginRight: "12px" }}
                    ></Checkbox>
                    Closed
                  </div>
                  <div>
                    <Checkbox
                      checked={filters.forSale}
                      onChange={() =>
                        setFilters({ ...filters, forSale: !filters.forSale })
                      }
                      style={{ marginRight: "12px" }}
                    ></Checkbox>
                    For Sale
                  </div>
                </div>

                {/* <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                  <div style={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: "center" }}>
                    <div>
                      <Checkbox
                        checked={filters?.excludeAlreadyInvested}
                        onChange={() => setFilters({ ...filters, excludeAlreadyInvested: !filters.excludeAlreadyInvested })}
                      ></Checkbox>
                    </div>
                    <div style={{ fontSize: "14px", lineHeight: "24px", fontWeight: "400", color: "#242424" }}>Exclude already invested</div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: "center" }}>
                    <div>
                      <Checkbox
                        checked={filters?.buybackOnly}
                        onChange={() => setFilters({ ...filters, buybackOnly: !filters.buybackOnly })}
                      ></Checkbox>
                    </div>
                    <div style={{ fontSize: "14px", lineHeight: "24px", fontWeight: "400", color: "#242424" }}>Show loans only with Buyback obligation</div>
                  </div>
                </div> */}
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "24px" }}
                >
                  <div>
                    <Button
                      type="primary"
                      block
                      htmlType="submit"
                      className="new_button_filled"
                      style={{ height: "40px", width: "130px" }}
                      onClick={() => {
                        handleSearch();
                        setSearchStatus(true);
                      }}
                    >
                      Apply filter
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        height: "24px",
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: "400",
                        color: "#242424",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderBottom: "1px solid #24242426",
                      }}
                      onClick={() => {
                        clearFlitter();
                        ResetData();
                        setSelectedWalletAddress("");
                        setSearchStatus(true);
                      }}
                    >
                      Reset
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Collapse>
          </div>
        </Accordion>
      </div>
    </div>
  );
}
