import React from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistor, store } from "./Store/store";
import { ConfigProvider } from "antd";
import { PersistGate } from "redux-persist/integration/react";
import ReactGA from "react-ga4";
const root = ReactDOM.createRoot(document.getElementById("root"));
ReactGA.initialize([{ trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID }]);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "Inter",
              colorPrimary: "#DE3712",
              // colorInfo: "#2E96F5",
              // colorSuccess: "#45C01F",
              // colorWarning: "#E8D529",
              colorError: "#D02342",
              // colorTextDisabled: "#000",
            },
            components: {
              Table: {
                bodySortBg: "#fff",
                headerSortActiveBg: "#fff",
              },
              Button: {
                defaultBg: "#ec441f",
                defaultColor: "#fff",
                defaultHoverBg: "rgba(236, 68, 31, 0.85)",
                defaultActiveBg: "rgba(236, 68, 31, 0.70)",
                defaultActiveColor: "#fff",
                defaultHoverColor: "#fff",
              },
            },
          }}
        >
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
