import React, { useEffect, useState } from "react";
import "./style.css";
import add_circle from "../../Assets/images/add-circle-outline.svg";
import { MetaMaskAvatar } from "react-metamask-avatar";
import { CaretDownOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import Modal2fa from "../Modal2fa/Modal2fa";
import DepositOnHold from "../StatusModels/DepositOnHold";
import { useDispatch, useSelector } from "react-redux";
import { WalletTestData } from "../../Helper_Data/SelectBoxData";
import { Avatar } from "antd";
import { setSelectedWalletAddress } from "../../Store/reducers/global";
import { clientApi, GetWalletBalance } from "../../Utils/AxiosCall";
import { newNumberformat } from "../../Helper/HelperFunction";
import { useWalletInfo, useWeb3Modal } from "@web3modal/wagmi/react";
import { message } from "antd";
import { walletLogos } from "../../Helper/CustomIcons";
import deleteicon from "../../Assets/images/delete_icon.svg";
import { Modal } from "antd";
const OpenWallet = () => {
  const { open, close } = useWeb3Modal();
  // console.log("Opening wallet...");
  close();
  try {
    // console.log("Opening wallet1...");
    setTimeout(() => {
      // console.log("Opening wallet2...");
      close();
      open();
    }, 2000); // 2 seconds delay
  } catch (error) {
    console.error("Failed to open wallet:", error);
  }
};
export default function WalletConnectNavBar({
  connectWallet,
  SwitchWallet,
  twoFAModal,
  setTwoFAModal,
  depositOnHoldModal,
  setDepositOnHoldModal,
}) {
  const dispatch = useDispatch();
  const stateDebug = useSelector((state) => state.global);
  const SelectedWalletAddress = useSelector(
    (state) => state?.global?.SelectedWalletAddress
  );
  // console.log(stateDebug);
  const { address, isConnecting, isConnected, isDisconnected, status } =
    useAccount();
  const { disconnect } = useDisconnect();
  useEffect(() => {
    if (!SelectedWalletAddress && address) {
      dispatch(setSelectedWalletAddress(address));
    }
  }, [address, SelectedWalletAddress, dispatch]);
  const localAddress = localStorage.getItem("walletAddress");
  // const [addresss, setAddresss] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);

  function shortenAddress(chars = 4) {
    return `${SelectedWalletAddress?.substring(
      0,
      4
    )}...${SelectedWalletAddress?.substring(
      SelectedWalletAddress?.length - 4
    )}`;
  }

  // useEffect(() => {
  //     if (address?.length > 11) {
  //         setAddresss(address);
  //     } else {
  //         setAddresss("");
  //     }
  // }, [localAddress, isConnected, isDisconnected, address]);

  // console.log("addresss :" + addresss, "localAddress :" + localAddress);

  useEffect(() => {
    if (
      !address ||
      address !== "" ||
      address !== null ||
      address !== undefined ||
      address !== "undefined"
    ) {
      dispatch(setSelectedWalletAddress(address));
    }
  }, [address]);

  const handleDropdownVisibleChange = (visible) => {
    setDropdownVisible(visible);
  };
  const { walletInfo } = useWalletInfo();
  const [walletListDetails, setWalletListDetails] = useState({});
  const [walletListDetailsSet, setWalletListDetailsSet] = useState({});
  const { connectAsync, connect, connectors } = useConnect();
  const [walletsName, setWalletsName] = useState("");
  const [walletConnectDetails, setWalletConnectDetails] = useState({});
  const [SelectedWalletDetails, setSelectedWalletDetails] = useState({});
  const [FUCapitalWalletBalance, setFUCapitalWalletBalance] = useState({});
  const Get = async () => {
    try {
      const response = await GetWalletBalance();
      // console.log(response);
      setWalletListDetailsSet(response);
      // setWalletListDetails(response)
    } catch (error) {}
  };
  useEffect(() => {
    const connector = findConnectorById(connectors, walletsName);
    if (connector) {
      // console.log("Connector found:", connector);
      setWalletConnectDetails(connector);
    } else {
      // console.log("Connector not found");
    }
  }, [connectors, walletInfo?.name, walletsName, SelectedWalletAddress]);

  const findConnectorById = (connectors, id) => {
    return connectors.find((connector) => connector.name === id) || null;
  };

  const handleConnect = async (walletsName) => {
    const connector = findConnectorById(connectors, walletsName);
    if (connector) {
      // console.log("Connector found:", connector);
      setWalletConnectDetails(connector);
    } else {
      // console.log("Connector not found");
    }
    if (connector) {
      await connect({ connector: connector });
      // console.log("Connected to wallet:", connector?.name);
    } else {
      // console.log("Connector not found");
      message.error("Wallet not found");
    }
  };
  useEffect(() => {
    Get();
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      Get();
    }, 10000); // 10000 milliseconds = 10 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    try {
      if (
        address &&
        !walletListDetailsSet?.walletBalances?.some(
          (wallet) => wallet.walletAddress === address
        )
      ) {
        const updatedWalletBalances = [
          ...walletListDetailsSet.walletBalances,
          {
            walletName: walletInfo?.name ?? "", // or any default name you want to give
            walletAddress: address,
            walletBalance: 0, // or any default balance you want to give
          },
        ];
        setWalletListDetails({
          ...walletListDetailsSet,
          walletBalances: updatedWalletBalances,
        });
      } else {
        setWalletListDetails(walletListDetailsSet);
      }
    } catch (error) {
      console.error("Error updating wallet list details:", error);
    }
  }, [walletListDetailsSet, address]);
  const handleChange = (value, option) => {
    // console.log(value);
    // console.log(option['data-item']?.walletName);

    if (value !== "newWallet") {
      setWalletsName(option);
      setSelectedWalletAddress(value);
      disconnect();
      setTimeout(async () => {
        await handleConnect(option);
      }, 2000);
    } else {
      // console.log("new wallet");
      disconnect();
      OpenWallet();
      setSelectedWalletAddress("");
    }
    const addressToUse = value;

    const selectedItem = walletListDetails?.walletBalances?.find(
      (item) => item.walletAddress === addressToUse
    );
    // console.log(selectedItem);
    setSelectedWalletDetails(selectedItem);
  };

  const navbarWalletAddress = address;

  useEffect(() => {
    // console.log(walletListDetails);

    if (navbarWalletAddress && walletListDetails) {
      // console.log(walletListDetails);
      setSelectedWalletAddress(navbarWalletAddress);
      const selectedWallet = walletListDetails?.walletBalances?.find(
        (wallet) => wallet.walletAddress === navbarWalletAddress
      );
      // console.log(selectedWallet);
      if (selectedWallet) {
        setFUCapitalWalletBalance(selectedWallet?.walletBalance);
        setSelectedWalletDetails(selectedWallet);
      }
    } else if (
      !SelectedWalletAddress &&
      walletListDetails?.walletBalances?.length > 0
    ) {
      setFUCapitalWalletBalance(
        walletListDetails?.walletBalances[0]?.walletBalance
      );
      setSelectedWalletAddress(
        walletListDetails?.walletBalances[0]?.WalletAddress
      );
      setSelectedWalletDetails(walletListDetails?.walletBalances[0]);
    }
  }, [walletListDetails, navbarWalletAddress]);
  function getWalletLogo(walletID) {
    return walletLogos[walletID] || false;
  }
  return (
    <div>
      {(!walletListDetails?.walletBalances ||
        walletListDetails.walletBalances.length < 1) &&
        !SelectedWalletAddress && (
          <div
            className="div-navbar-wallet-connect"
            onClick={() => connectWallet()}
          >
            <div
              style={{
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "400",
              }}
            >
              Connect new wallet
            </div>
            <div>
              <img src={add_circle} alt="add circle" />
            </div>
          </div>
        )}
      {(walletListDetails?.walletBalances?.length > 0 ||
        SelectedWalletAddress) && (
        <Dropdown
          dropdownRender={(menu) => (
            <DropDownOptions
              SwitchWallet={SwitchWallet}
              disconnect={disconnect}
              connectWallet={connectWallet}
              setDropdownVisible={setDropdownVisible} // Pass the setter to child component
              walletListDetails={walletListDetails?.walletBalances}
              totalBalance={walletListDetails?.totalBalance}
              handleChange={handleChange}
            />
          )}
          trigger={["click"]}
          placement="bottomRight"
          visible={dropdownVisible}
          onVisibleChange={handleDropdownVisibleChange}
        >
          <div
            className="div-navbar-wallet-connect-after"
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {SelectedWalletAddress ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "8px",
                }}
              >
                <>
                  {getWalletLogo(SelectedWalletDetails?.walletName) ? (
                    <img
                      src={getWalletLogo(SelectedWalletDetails?.walletName)}
                      style={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "50%",
                      }}
                      alt="wallet logo"
                    />
                  ) : (
                    <MetaMaskAvatar address={SelectedWalletAddress} size={24} />
                  )}
                </>
                {shortenAddress()}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <Avatar
                  className="header-dropdown-avatar"
                  size={24}
                  style={{ fontSize: "12px", fontWeight: 700, lineHeight: 16 }}
                >
                  {walletListDetails?.walletBalances?.length}
                </Avatar>
                <span>All linked wallets</span>
              </div>
            )}
            <div>
              <CaretDownOutlined />
            </div>
          </div>
        </Dropdown>
      )}
      <DepositOnHold
        isOpen={depositOnHoldModal}
        setIsOpen={setDepositOnHoldModal}
      />
    </div>
  );
}

const DropDownOptions = ({
  SwitchWallet,
  disconnect,
  connectWallet,
  setDropdownVisible,
  walletListDetails,
  totalBalance,
  handleChange,
}) => {
  // console.log(walletListDetails);
  const SelectedStableCoin = useSelector(
    (state) => state?.global?.SelectedStableCoin
  );
  const dispatch = useDispatch();
  const SelectedWalletAddress = useSelector(
    (state) => state?.global?.SelectedWalletAddress
  );
  const itemHeight = 48; // Assuming each item has a height of 48px
  let containerHeight = walletListDetails?.length * itemHeight;
  containerHeight = containerHeight + 62;
  if (walletListDetails?.length >= 1) {
    containerHeight = containerHeight + 48;
  }
  if (walletListDetails?.length == 10) {
    containerHeight = containerHeight - 48;
  }
  if (walletListDetails?.length == 0) {
    containerHeight = 48;
  }

  useEffect(() => {
    if (!SelectedWalletAddress) {
      dispatch(setSelectedWalletAddress(""));
    }
  }, [SelectedWalletAddress, dispatch]);

  const handleItemClick = (address, walletName) => {
    dispatch(setSelectedWalletAddress(address == "newWallet" ? "" : address));
    handleChange(address, walletName);
    setDropdownVisible(false); // Close the dropdown
  };
  function shortenAddress(address) {
    return `${address?.substring(0, 4)}...${address?.substring(
      address?.length - 4
    )}`;
  }
  const { address } = useAccount();
  function getWalletLogo(walletID) {
    return walletLogos[walletID] || false;
  }
  return (
    <div
      style={{
        width: "318px",
        height: containerHeight,
        borderRadius: "6px",
        background: "#242424",
      }}
    >
      <div className="header-dropdown-menu-bottom-switch">
        <div style={{ height: "8px" }}></div>
        <div className="div-navbar-wallet-connect-after-label-container">
          <div className="div-navbar-wallet-connect-after-label">
            Linked wallet
          </div>
          <div className="div-navbar-wallet-connect-after-label">
            Available funds, {SelectedStableCoin}
          </div>
        </div>
        {walletListDetails?.map((item, index) => (
          <div
            key={index}
            className={`header-dropdown-menu-item ${
              address === item?.walletAddress
                ? "header-dropdown-menu-item-active"
                : ""
            }`}
            onClick={() =>
              handleItemClick(item?.walletAddress, item?.walletName)
            }
            style={
              item?.walletAddress === SelectedWalletAddress
                ? { borderLeft: "4px solid #EC441F" }
                : { borderLeft: "4px solid transparent" }
            }
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "8px",
                }}
              >
                <>
                  {getWalletLogo(item?.walletName) ? (
                    <img
                      src={getWalletLogo(item?.walletName)}
                      style={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "50%",
                      }}
                      alt="wallet logo"
                    />
                  ) : (
                    <MetaMaskAvatar address={item?.walletAddress} size={24} />
                  )}
                </>
                {shortenAddress(item?.walletAddress)}&nbsp;
                <span style={{ fontSize: "12px" }}>{`${
                  !getWalletLogo(item?.walletName)
                    ? "(" + item?.walletName + ")"
                    : ""
                }`}</span>
              </div>
              <div>{newNumberformat(item?.walletBalance || 0)} </div>
            </div>
          </div>
        ))}
        {walletListDetails.length < 10 && (
          <div
            style={{ padding: "12px 24px " }}
            onClick={() => {
              setDropdownVisible(false);
            }}
          >
            <div
              className="div-navbar-wallet-connect-button "
              onClick={() => handleItemClick("newWallet", "newWallet")}
            >
              <div
                style={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "400",
                }}
              >
                Connect new wallet
              </div>
              <div>
                <img src={add_circle} alt="add circle" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
