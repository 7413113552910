import React from "react";
import "./style.scss";
import { DatePicker } from "antd";

function CustomDatePicker({
  disabledDate,
  format = "YYYY-MM-DD",
  value,
  placeholder = "Start",
  onChange,
  onOpenChange,
  showNow,
  allowClear,
  popupClassName,
  suffixIcon,
  popupStyle,
  renderExtraFooter,
  className,
  open,
}) {
  return (
    <DatePicker
      disabledDate={disabledDate}
      format={format}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      open={open}
      onOpenChange={onOpenChange}
      showNow={showNow}
      allowClear={allowClear}
      popupClassName={popupClassName}
      suffixIcon={suffixIcon}
      popupStyle={popupStyle}
      renderExtraFooter={renderExtraFooter}
      className={className}
    />
  );
}

export default CustomDatePicker;
